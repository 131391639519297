import { hasClass } from "./utils";

export const init = () => {
  document.querySelectorAll('[data-bs-toggle="collapse"]').forEach(el => {
    el.addEventListener('click', maybeClearFields);
  });
};

const maybeClearFields = (e) => {
  // User clicks only except for hidden triggers
  if (e.isTrusted && e.target.type === 'checkbox' && !e.target.checked) {
    clearFields(e.target.dataset.bsTarget);
  } else if (hasClass(e.target, 'visually-hidden')) {
    clearFields(e.target.dataset.bsTarget);
  }
};

const clearFields = (targetId) => {
  // Text fields
  document.querySelector(targetId).querySelectorAll('.form-control, .form-select').forEach(el => {
    el.value = '';
  });
  // Checkboxes
  document.querySelector(targetId).querySelectorAll('.form-check-input[type="checkbox"]').forEach(el => {
    if (el.checked) {
      el.click();
    }
  });
  // Radios
  document.querySelector(targetId).querySelectorAll('.form-check-input[type="radio"]').forEach(el => {
    el.checked = false;
  });
};