const urlParams = new URLSearchParams(window.location.search);

const appeal = urlParams.get('appeal');
const csId = urlParams.get('csid');
const preselectedFund = urlParams.get('f');
const quickForm = urlParams.get('v');
const scode = urlParams.get('scode');
const today = urlParams.get('today');
const tribute = urlParams.get('tribute');
const recur = urlParams.get('rec');

export {
  appeal,
  csId,
  preselectedFund,
  quickForm,
  scode,
  today,
  tribute,
  recur
}