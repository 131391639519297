"use strict";

import FormPersistence from 'form-persistence';
import * as constants from "./constants";
import * as gifts from "./gifts";
import { goToPanel } from "./nav";
import { state } from "./state";
import * as utils from "./utils";
import { updateSummary } from './summary';

export const clearStorage = () => {
  FormPersistence.clearStorage(constants.elements.bbForm, {
    uuid: 'savedForm',
  });
  localStorage.removeItem('activeStep');
  localStorage.removeItem('savedNavPills');
  localStorage.removeItem('gifts');;
  localStorage.removeItem('giftsConfirm');
};

export const restoreForm = (savedGifts) => {
  const activeStep = localStorage.getItem('activeStep');

  state.isReturning = true;
  restoreGifts();
  setTimeout(() => {
    window.adf.initForm();
    goToPanel(activeStep ? parseInt(activeStep) : 1);
  }, 100);
  FormPersistence.load(constants.elements.bbForm, {
    uuid: 'savedForm',
  });
  gifts.initGiftActions();
  gifts.updateGiftAmountTotal();
  setTimeout(() => {
    restoreChecks();
  }, 100);
  updateSummary();
  restorePills(parseInt(activeStep));
};

const restoreChecks = () => {
  document.querySelectorAll('input[type="checkbox"]:checked, input[type="radio"]:checked').forEach(el => {
    if (!el.checked) {
      el.click();
    }
    utils.triggerEvent(el, 'click');
  });
};

const restoreGifts = (savedGifts) => {
  constants.elements.gifts.innerHTML = JSON.parse(localStorage.getItem('gifts'));
  constants.elements.giftsConfirm.innerHTML = JSON.parse(localStorage.getItem('giftsConfirm'));
};

const restorePills = (activeStep) => {
  constants.elements.panelBtns.forEach((el, index) => {
    if (index < activeStep) {
      el.parentElement.classList.add('complete');
    }
  });
};

export const saveForm = (step) => {
  FormPersistence.save(constants.elements.bbForm, {
    uuid: 'savedForm',
  });
  // Save current form HTML
  localStorage.setItem('activeStep', step);
  localStorage.setItem('savedNavPills', JSON.stringify(constants.elements.navPills.innerHTML));
  localStorage.setItem('gifts', JSON.stringify(constants.elements.gifts.innerHTML));
  localStorage.setItem('giftsConfirm', JSON.stringify(constants.elements.giftsConfirm.innerHTML));
};