import { disablePledge, enablePledge } from "./pledge";
import { updateSummary } from "./summary";

export const init = () => {
  document.querySelectorAll('[name="payment-method"]').forEach(el => {
    el.addEventListener('click', e => {
      updateSummary();
    });
  });

  document.getElementById('creditCard').addEventListener('click', enablePledge);
  document.getElementById('payroll').addEventListener('click', disablePledge);
};