import * as constants from "./constants";
import { options } from "./options";

export const init = () => {
  initBtns();
  if (options.otherAmount) {
    initOtherAmount();
  }
};

const clearBtns = (e) => {
  constants.elements.amountBtns.forEach(el => {
    el.checked = false;
  })
};

const clearOtherAmount = (e) => {
  constants.elements.otherAmount.value = '';
};

const initBtns = () => {
  constants.elements.amountBtns.forEach(el => {
    if (options.otherAmount) {
      el.addEventListener('click', clearOtherAmount)
    }
  });
};

const initOtherAmount = () => {
  constants.elements.otherAmount.addEventListener('focus', clearBtns);
};

export const resetAmount = () => {
  const checkedAmount = document.querySelector('[name="gift-amount"]:checked');

  if (checkedAmount) {
    checkedAmount.checked = false;
  }
};