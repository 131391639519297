"use strict";

import '../sass/styles.scss';
import FormPersistence from 'form-persistence';
import { init as api } from "./components/api";
import { init as collapse } from "./components/collapse";
import { init as funds } from "./components/funds";
import { init as giftAmount } from "./components/giftAmount";
import { init as gifts } from "./components/gifts";
import { init as nav } from "./components/nav";
import { toggleQuickForm } from "./components/quickForm";
import { options } from "./components/options";
import { init as paymentMethod } from "./components/paymentMethod";
import { init as payroll } from "./components/payroll";
import * as persistence from "./components/persistence";
import { init as polyfills } from "./components/polyfills";
import { init as recurrence } from "./components/recurrence";
import * as ui from "./components/ui.js"
import * as urlParams from "./components/urlParams.js"
import { init as validation } from "./components/validation";
import { init as yourInfo } from "./components/yourInfo";

window.adf = window.adf || {};

window.adf.initForm = () => {
  if (urlParams.quickForm === '1') {
    toggleQuickForm();
  }
  api();
  collapse();
  funds(false);
  giftAmount();
  gifts();
  nav();
  paymentMethod();
  if (options.payroll) {
    payroll();
  }
  polyfills();
  if (options.recurrence) {
    recurrence();
  }
  ui.init();
  validation();
  yourInfo();
};

window.adf.maybeRestore = () => {
  const savedGifts = localStorage.getItem('gifts');
  if (urlParams.quickForm === '1') {
    window.adf.initForm();
  } else {
    if (savedGifts) {
      const modal = new bootstrap.Modal(document.getElementById('modalRestore'));
      if (modal) {
        modal.show();
        document.getElementById('modalContinue').addEventListener('click', e => {
          persistence.restoreForm(savedGifts);
        });
        document.getElementById('modalReset').addEventListener('click', e => {
          persistence.clearStorage();
          window.adf.initForm();
        });
      }
    } else {
      window.adf.initForm();
    }
  }
};

document.addEventListener('DOMContentLoaded', e => {
  if (!urlParams.csId) {
    window.adf.maybeRestore();
  } else {
    ui.showPage();
  }
});