import { addPreSelectedDonation } from "./funds";
import { goToPanel } from "./nav";
import { state } from "./state";
import * as urlParams from "./urlParams";
import * as utils from "./utils";

const isTribute = document.getElementById('isTribute');


const showTributeFields = () => {
  document.getElementById('isTribute').click();
};

const getTributeFund = (tribute) => {
  return tribute.Values[2] !== '' ? tribute.Values[2] : guids.defaultFund;
};

const setTributeType = (tribute) => {
  const tributeType = tribute.Values[3];
  let tributeBtn;

  if (tributeType !== '') {
    tributeBtn = document.querySelector(`[name="tribute-type"][value="${tributeType}"]`);

    if (tributeBtn) {
      tributeBtn.click();
    }
  }
};

const showTributeName = (tribute) => {
  const tributeName = document.getElementById('tributeName');
  const tributeText = tribute.Values[1];

  if (tributeText !== '') {
    utils.hideElement(document.getElementById('honoreeName'));
    tributeName.querySelector('.form-control').textContent = tributeText;
    utils.showElement(tributeName);
  }
};

const addTribute = (tribute) => {
  const defaultAmount = ' ';
  const editStatus = '1';
  const removeStatus = '1';
  const tributeFund = getTributeFund(tribute);

  addPreSelectedDonation(tributeFund, defaultAmount, editStatus, removeStatus);
  utils.hideRecurrence();
  showTributeFields();
  setTributeType(tribute);
  showTributeName(tribute);

  goToPanel(1);
  console.log(tribute);
};

export const disableTribute = (e) => {
  if (isTribute.checked && !isTribute.disabled) {
    isTribute.click();
  }
  isTribute.disabled = true;
};

export const enableTribute = (e) => {
  isTribute.disabled = false;
};

const findTribute = (tributeId, tributes) => {
  return tributes.filter(obj => {
    return obj.Values[0] === tributeId;
  });
};

export const getTributes = (tributeId) => {
  const queryService = new BLACKBAUD.api.QueryService();

  const successCallback = (data) => {
    console.log(data);
    const tribute = findTribute(tributeId, data.Rows);

    if (tribute[0]) {
      state.isTribute = true;
      addTribute(tribute[0]);
    }
  };

  const failureCallback = (errors) => {
    console.log(errors);
  };

  if (queryService) {
    queryService.getResults(guids.tribute, successCallback, failureCallback);
  }
};

export const maybeTribute = () => {
  if (urlParams.tribute && !state.isReturning) {
    getTributes(urlParams.tribute);
  }
};