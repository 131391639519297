import { options } from "./options";
import { updateSummary } from "./summary";
import { enableTribute, disableTribute } from "./tribute";

export const init = () => {
  initRecurrenceBtns();
};

export const getGiftFrequency = () => {
  return document.querySelector('[name="gift-recurrence"]:checked').nextElementSibling.textContent;
};

/*export const StartDatePick = () => {
  document.querySelector('input[name="recurrenceStartDate"]');
}

export const EndStartDatePick = () => {
  document.querySelector('input[name="recurrenceEndDate"]');;
}
*/


const initRecurrenceBtns = () => {
  const oneTimeBtn = document.querySelector('[name="gift-recurrence"][value="1"]');
  const monthlyBtn = document.querySelector('[name="gift-recurrence"][value="2"]');
//  const quarterlyBtn = document.querySelector('[name="gift-recurrence"][value="3"]');
  const annuallyBtn = document.querySelector('[name="gift-recurrence"][value="4"]');
//  const fourweekBtn = document.querySelector('[name="gift-recurrence"][value="7"]');

  if (options.tribute) {
    if (oneTimeBtn) {
      oneTimeBtn.addEventListener('click', e => {
        enableTribute(e);
        updateSummary();
      });      
      document.querySelector('[name="gift-recurrence"]').value= '1';
    }
  /*  if (quarterlyBtn) {
      quarterlyBtn.addEventListener('click', e => {
        disableTribute(e);
        updateSummary();
      });
      document.querySelector('[name="gift-recurrence"]').value= '3';
    }*/
    if (monthlyBtn) {
      monthlyBtn.addEventListener('click', e => {
        disableTribute(e);
        updateSummary();
      });
      document.querySelector('[name="gift-recurrence"]').value= '2';
    }
    if (annuallyBtn) {
      annuallyBtn.addEventListener('click', e => {
        disableTribute(e);
        updateSummary();
      });
      document.querySelector('[name="gift-recurrence"]').value= '4';
    }
/*    if (fourweekBtn) {
      fourweekBtn.addEventListener('click', e => {
        disableTribute(e);
        updateSummary();        
      });
      document.querySelector('[name="gift-recurrence"]').value= '7';
    }*/
  }

};

// const updateRecurrenceTotalAmount = (numPayments) => {
//   const giftAmount = document.querySelector('.js-total').innerText;
//   const giftAmountRecurrence = giftAmount * numPayments;

//   const els = document.querySelectorAll('.js-total-recurring');
//   if (els.length > 0) {
//     els.forEach(function(el) {
//       el.innerText = '$' + utils.numToFloat(giftAmountRecurrence, 2);
//     });
//   }
// };

 const updateRecurrenceDates = (numPayments) => {
   const today = new Date();
   const startDate = new Date();
//   const endDate = giftFrequency === 'monthly' ? new Date(startDate.setMonth(startDate.getMonth() + numPayments)) : new Date(startDate.setFullYear(startDate.getFullYear() + numPayments));
   const endDate = giftFrequency === 'monthly' ? new Date(startDate.setMonth(startDate.getMonth() + numPayments)) : new Date(startDate.setFullYear(startDate.getFullYear() + numPayments));
   const localeEndDate = endDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
   const els = document.querySelectorAll('.js-gift-end-date');

   document.getElementById('gift-start-date').value = today;
   document.getElementById('gift-end-date').value = endDate;
   document.getElementById('StartdatePicker').value = today;
   document.getElementById('enddatePicker').value = endDate;

   if (els.length > 0) {
     els.forEach(function(el) {
       el.innerText = localeEndDate;
     });
   }
 };

 const getRecurringPayments = () => {
   const numberOfInstallments = document.getElementById('gift-num-payments').value;
   let giftFrequency = document.querySelector('[name="gift-type"]:checked').nextElementSibling.innerText;
   const totalGiftAmount = document.getElementById('gift-amount-total-recurring').innerText;
   const recurringGiftAmount = ds.getRecurringGiftInstallmentAmount(parseFloat(totalGiftAmount.replace(/\$/, '')), numberOfInstallments);

   if (giftFrequency === 'Annually') {
     giftFrequency = 'Annual';
   }
   return numberOfInstallments + ' ' + giftFrequency + ' Payments of $' + recurringGiftAmount + '\nTotal Gift of ' + totalGiftAmount;
 };
