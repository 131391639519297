const isPledge = document.getElementById('isPledge');

export const disablePledge = (e) => {
  if (isPledge.checked && !isPledge.disabled) {
    isPledge.click();
  }
  isPledge.disabled = true;
};

export const enablePledge = (e) => {
  isPledge.disabled = false;
};