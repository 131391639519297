import Datepicker from 'vanillajs-datepicker/Datepicker';
import * as dayjs from 'dayjs';
import Inputmask from "inputmask";
import * as constants from "./constants";
import { options } from "./options";

export const init = () => {
  bootstrap();
  datepicker();
  fulldatepicker();
  handleCategorySelect();
  inputMask(constants.elements.otherAmount);
}

const bootstrap = () => {};

export const datepicker = () => {
  const maxDate = dayjs().add(4, 'year').year().toString();

  document.querySelectorAll('.date').forEach(el => {
    new Datepicker(el, {
      autohide: true,
      buttonClass: 'btn',
      format: 'yyyy',
      minDate: '1915',
      maxDate: maxDate,
      maxView: 2,
      pickLevel: 2,
      startView: 2,
    });
  });
};

export const fulldatepicker = () => {
  const sdate = dayjs().toString();
  //const edate = dayjs("2024-05-31");

  document.querySelectorAll('.datepicker_input').forEach(el => {
    new Datepicker(el, {
      autohide: true,
      buttonClass: 'btn',
      startView: 0,
      mindate: sdate//,
      //maxdate: edate
    });
  });
  document.getElementById('recurrenceStartDate').value = dayjs().format('MM/DD/YYYY');
  var endDate = new Date();
  endDate = dayjs().add(5, 'year');
  document.getElementById('recurrenceEndDate').value = endDate.format('MM/DD/YYYY');
};

const handleCategorySelect = () => {
  const funds = document.querySelector('.funds');

  if (funds) {
    funds.querySelectorAll('[data-bs-toggle="tab"]').forEach(el => {
      el.addEventListener('click', e => {
        funds.classList.add(constants.classes.activeClass);
      });
    });
  }
};

export const inputMask = (el) => {
  const employeeIdMask = new Inputmask('99999999', {});
  const currencyMask = new Inputmask('currency', {
    autoUnmask: true
  });

  // Currency
  if (options.otherAmount) {
    currencyMask.mask(el);
  }

  // Employee Id
  if (options.payroll) {
    employeeIdMask.mask(document.getElementById('employeeId'), {
      'placeholder': ' '
    });
  }
};

export const hidePage = () => {
  constants.elements.page.classList.add('loading');
};

export const showPage = () => {
  constants.elements.page.classList.remove('loading');
};