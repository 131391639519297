import * as utils from "./utils";

export const init = () => {
  const employeeTrigger = document.getElementById('employeeTrigger');
  const payrollTrigger = document.getElementById('payrollTrigger');

  document.getElementById('one-time').addEventListener('click', e => {
    // Hide employee question
    if (employeeTrigger.checked) {
      employeeTrigger.click();
    }
    // Select one time gift
    document.getElementById('creditCard').click();
    // Hide payroll fields
    if (payrollTrigger.checked) {
      payrollTrigger.click();
    }
  });

  document.getElementById('monthly').addEventListener('click', e => {
    // Show employee question
    if (!employeeTrigger.checked) {
      employeeTrigger.click();
    }
  });

  document.querySelectorAll('[name="payment-method"]').forEach(el => {
    el.addEventListener('click', e => {
      // Toggle payroll fields visibility
      if (e.isTrusted && document.getElementById('isEmployee').checked) {
        payrollTrigger.click();
      } else if (e.currentTarget.id === 'payroll' && !payrollTrigger.checked) {
        payrollTrigger.click();
      } else if (payrollTrigger.checked) {
        payrollTrigger.click();
      }
    });
  });

  document.getElementById('payrollTrigger').addEventListener('click', e => {
    const orgFields = document.getElementById('orgFields');

    // Toggle organization fields visibility
    if (orgFields) {
      if (utils.hasClass(orgFields, 'show')) {
        orgFields.classList.remove('show');
      } else {
        orgFields.classList.add('show');
      }
    }
  });

};