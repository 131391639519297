import * as constants from "./constants";
import { hideGiftLevels } from "./funds";
import { resetAmount } from "./giftAmount";
import { resetCategories, resetFunds, resetSearch } from "./gifts";
import { options } from "./options";
import { state } from "./state";
import { datepicker } from "./ui";
import { addClass } from "./utils";
import * as validation from "./validation";

export const init = () => {
  handleGoToFirstPanel();
  initBackButton();
  initCancelButton();
  initPanelButtons();
  initReviewButton();
  initCommitmentGiftButtons();
};

const resetNav = () => {
  constants.elements.navPills.querySelectorAll('.nav-item').forEach(el => {
    el.classList.remove('complete');
  });
};

const getPanelIndex = (id) => {
  return constants.panels.findIndex(panel => panel.id === id);
};

export const goToPanel = (index) => {
  const panelBtn = document.querySelector(`[aria-controls="${constants.panels[index].id}"]`);

  validation.fv.plugins.wizard.currentStep = index;
  updateNextButtonText(index);
  addClass(panelBtn.parentElement, 'complete');
  panelBtn.click();
  toggleBackButton(constants.panels[index].backBtn);
};

const handleGoToFirstPanel = () => {
  window.addEventListener('goToFirstPanel', e => {
    validation.fv.plugins.wizard.currentStep = 0;
    updateNextButtonText(0);
    document.querySelector(`[aria-controls="${constants.panels[0].id}"]`).click();
    if (e.resetNav) {
      resetNav();
    }
  });
};

const initBackButton = () => {
  if (options.btnBack) {
    constants.elements.btnBack.addEventListener('click', e => {
      const prevPanel = document.querySelector('.panel.active').previousElementSibling;
      let prevPanelBtn = null;
      let panelIndex = 0;

      if (prevPanel) {
        prevPanelBtn = document.querySelector(`[aria-controls="${prevPanel.id}"]`);
        panelIndex = Array.prototype.indexOf.call(constants.elements.panels, prevPanel);

        if (prevPanelBtn && constants.elements.panels[panelIndex]) {
          toggleBackButton(constants.panels[panelIndex].backBtn);
          updateNextButtonText(panelIndex);
          validation.fv.plugins.wizard.currentStep = panelIndex + 1;
          prevPanelBtn.click();
        }
      }
    });
  }
};

const initCancelButton = () => {
  if (options.btnCancel) {
    constants.elements.btnCancel.addEventListener('click', e => {
      const panelBtn = document.querySelector(`[aria-controls="${constants.panels[1].id}"]`).parentElement;

      resetCategories();
      resetFunds();
      resetSearch();
      hideGiftLevels();
      resetAmount();

      state.isEditingGift = false;
      state.isAnotherGift = false;
      toggleCancelButton();
      updateNextButtonText(1);
      validation.fv.plugins.wizard.currentStep = 1;
      panelBtn.classList.add(constants.classes.activeClass);
      panelBtn.querySelector('.nav-link').click();
      panelBtn.classList.remove(constants.classes.activeClass);
    });
  }
};

export const initPanelButtons = () => {
  setTimeout(() => {
    constants.elements.panelBtns.forEach(el => {
      el.addEventListener('click', e => {
        const panelIndex = Array.prototype.indexOf.call(constants.elements.panels, document.querySelector(e.currentTarget.dataset.bsTarget));

        // User click
        if (e.isTrusted) {
          if (panelIndex === 0) {
            state.isEditingGift = false;
            state.isAnotherGift = true;
            toggleCancelButton();
          }
          updateNextButtonText(panelIndex);
          toggleBackButton(constants.panels[panelIndex].backBtn);
          validation.fv.plugins.wizard.currentStep = panelIndex;
        } else {
          toggleCancelButton(panelIndex);
        }
      });
    });
  }, 1000);
};

const initReviewButton = () => {
  document.querySelectorAll('.btn-review').forEach(el => {
    el.addEventListener('click', e => {
      goToPanel(getPanelIndex('giftReview'));
    });
  });
};

export const toggleBackButton = (displayBackButton) => {
  if (options.btnBack) {
    if (displayBackButton) {
      constants.elements.btnBack.parentElement.classList.remove(constants.classes.hiddenClass);
    } else {
      constants.elements.btnBack.parentElement.classList.add(constants.classes.hiddenClass);
    }
  }
};

export const toggleCancelButton = (index = 0) => {
  const cancel = document.querySelector('.form-submit .cancel');

  if (cancel) {
    if (index === 0 && (state.isAnotherGift || state.isEditingGift)) {
      cancel.classList.remove(constants.classes.hiddenClass);
    } else {
      cancel.classList.add(constants.classes.hiddenClass);
    }
  }
};

export const updateNextButtonText = (index) => {
  document.getElementById('btnNext').innerHTML = constants.panels[index].nextButtonHtml;
  document.querySelectorAll('.IsCommitment').forEach(el => {
    if(el.checked) {
      document.getElementById('annually').click();
    }
  });
};


export const updatePillText = (index) => {
  const panel = constants.panels[index - 1];
  const pillText = document.querySelector(`[aria-controls="${panel.id}"]`).querySelector('.pill-text');

  pillText.textContent = panel.completedText !== '' ? panel.completedText : pillText.textContent;
};

export const initCommitmentGiftButtons =() => {

  document.querySelectorAll('.IsCommitmentbtn').forEach(el => {
    el.addEventListener('click', e => {
      document.getElementById('giftsLabel').innerText = 'My Installment';
      document.getElementById('giftsTableTotal').innerText = 'My Installment Amount';
      document.getElementById('recurrbtns').classList.remove('d-none');
      document.getElementById('recurronetime').classList.add('d-none');
      document.getElementById('StartdatePicker').classList.remove('d-none');
      document.getElementById('recurrannually').classList.remove('d-none');
      document.getElementById('giftTableText').classList.remove('d-none');
      document.getElementById('annually').click();
    });
  })

  document.querySelectorAll('.IsGiftbtn').forEach(el => {
    el.addEventListener('click', e => {
      document.getElementById('giftsLabel').innerText = 'My Gifts';
      document.getElementById('giftsTableTotal').innerText = 'My Gift Amount';
      document.getElementById('recurrbtns').classList.add('d-none');
      document.getElementById('recurronetime').classList.remove('d-none');
      document.getElementById('StartdatePicker').classList.add('d-none');
      document.getElementById('recurrannually').classList.add('d-none');
      document.getElementById('giftTableText').classList.add('d-none');
      document.getElementById('one-time').click();
    
    });
  })

}