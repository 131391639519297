import * as constants from "./constants";
import * as persistence from "./persistence";
import * as gifts from "./gifts";
import { options } from "./options";
import * as search from "./search";
import { state } from "./state";
import { maybeTribute } from "./tribute";
import * as ui from "./ui";
import * as urlParams from "./urlParams";
import * as utils from "./utils";
import * as validation from "./validation";

export let allDesignations = [];
let featuredDesignations = [];
let displayDesignations = [];
let designationUnits = {};
let designationAreas = {};
let designationFunds = {};

const academicFunds = 'Academics';
const campusFunds = 'Campus Life';
const featuredFunds = 'Featured';
const priorityFunds = 'University Priorities';

const fundCategories = {
  'Academics': 'Academics',
  'Campus Life': 'Campus Life',
  'Featured': 'Featured'
};

let unitDropdowns = {};
unitDropdowns[academicFunds] = 'unitSelectAcademics';
unitDropdowns[campusFunds] = 'unitSelectCampus';

let fundDropdowns = {};
fundDropdowns[featuredFunds] = 'fundSelectFeatured';
fundDropdowns[priorityFunds] = 'fundSelectFeatured';

const giftUnits = document.querySelectorAll('.unit-select');
const giftAreas = document.querySelectorAll('.area-select');
const giftFunds = document.querySelectorAll('.fund-select');
const searchFunds = document.querySelector('.fund-search-select');

const searchParams = new URLSearchParams(window.location.search);

let selectedUnit;

let categories = [];
let categoryGroups = [];

export const init = (restore) => {
  initCategoryBtns();
  funds(restore);
}

const initCategoryBtns = () => {
  document.querySelectorAll('.category').forEach(el => {
    el.addEventListener('click', e => {
      gifts.resetFunds();
      hideGiftLevels();
    });
  });
};

export const hideGiftLevels = () => {
  const giftLevels = document.querySelector('.gift-levels');

  if (giftLevels) {
    utils.hideElement(giftLevels);
  }
};

const displayGiftLevels = (levels) => {
  const giftLevels = document.getElementById('giftLevels');

  if (giftLevels) {
    giftLevels.innerHTML = levels[0];
  }
};

export const maybeGiftLevels = (guid) => {
  const giftLevels = document.querySelector('.gift-levels');
  const fund = allDesignations.filter(function(obj) {
    return obj.guid === guid && obj.levels !== '';
  });

  if (giftLevels) {
    if (fund.length === 1) {
      displayGiftLevels(fund[0].levels);
      utils.showElement(giftLevels);
    } else {
      utils.hideElement(giftLevels);
    }
  }
};

const getGiftLevels = (guid, data) => {
  let levels = null;

  const fundGroup = data.Rows.filter(obj => {
    return obj.Values[2] === guid;
  });

  if (fundGroup.length > 0) {
    fundGroup.forEach(function(fund) {
      levels = fund.Values[8].split('\n\n0\n');
    });
  }
  return levels;
};

// const getDesignationValues = (values, data) => {
//   return {
//     lookupId: values[1],
//     guid: values[2],
//     // category: (values[6] === 'True') ? featuredFunds : values[4],
//     category: values[4],
//     name: values[5] !== '' ? values[5] : values[3],
//     isFeatured: values[6] === 'True',
//     levels: values[8] !== '' ? getGiftLevels(values[2], data) : '',
//     redirectURL: values[9],
//   };
// };

function cleanData() {
  // Can only use the designation if lookup ID, GUID and name are present
  allDesignations = allDesignations.filter(function(obj) {
    return obj.hasOwnProperty('lookupId') && obj.hasOwnProperty('guid') && obj.hasOwnProperty('name');
  });
}

const getAllDesignations = (data) => {
  // const isDuplicate = (guid, category) => {
  //   const found = allDesignations.filter(function(obj) {
  //     return obj.guid === guid;
  //   });

  //   if (found.length > 0) {
  //     if (found[0].category.split('>').length < category.split('>').length) {
  //       allDesignations = utils.removeArrayObj(allDesignations, found[0]);
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  return new Promise((resolve, reject) => {
    data.Rows.forEach(function(el) {
      // const designationValues = getDesignationValues(el.Values, data);
      const designationValues = {
        lookupId: el.Values[1],
        guid: el.Values[2],
        category: el.Values[4],
        name: el.Values[5] !== '' ? el.Values[5] : el.Values[3],
        isFeatured: el.Values[6] === 'True',
        levels: el.Values[8] !== '' ? getGiftLevels(el.Values[2], data) : '',
        redirectURL: el.Values[9],
      };
      const guid = el.Values[2];
      const category = el.Values[4];
      const isActive = el.Values[0] === 'True';
      const isFeatured = el.Values[6] === 'True';

      // if (isActive && category !== '') {
      if (isActive) {
        allDesignations.push(designationValues);
        // Fund may have a category and still be featured
        if (designationValues.isFeatured) {
          featuredDesignations.push({
            lookupId: el.Values[1],
            guid: el.Values[2],
            category: featuredFunds,
            name: el.Values[5] !== '' ? el.Values[5] : el.Values[3],
            isFeatured: el.Values[6] === 'True',
            levels: el.Values[8] !== '' ? getGiftLevels(el.Values[2], data) : '',
            redirectURL: el.Values[9],
          });
        }
      } else if (isActive && isFeatured) {
        allDesignations.splice(allDesignations.findIndex(item => item.guid === guid), 1);
        designationValues.category = featuredFunds;
        allDesignations.push(designationValues);
      }
    });
    return resolve();
  });
};

const getDisplayDesignations = () => {
  displayDesignations = allDesignations.filter(function(obj) {
    return (
      obj.category !== "" && (
        obj.category.indexOf(campusFunds) > -1 ||
        obj.category.indexOf(academicFunds) > -1 ||
        obj.category.indexOf(featuredFunds) > -1
      )
    );
  });
};

const getDesignationCategories = () => {
  Array.prototype.slice.call(allDesignations).concat(Array.prototype.slice.call(displayDesignations)).forEach(function(obj) {
    let categories = obj.category.split('>');
    if (categories.length > 2) {
      categories[2] = `${categories[1]}>${categories[2]}`;
    }
    obj.categories = categories;
  });
};

const getCategoryGroups = () => {
  categoryGroups = displayDesignations.reduce((r, a) => {
    r[a.categories[0]] = [...r[a.categories[0]] || [], a];
    return r;
  }, {});
}

const addOptions = (dropdown, opts) => {
  opts.forEach(function(el) {
    return typeof el === 'string' ? utils.createOption(dropdown, el) : utils.createDesignationOption(dropdown, el);
  });
};

const addGroupedOptions = (dropdown, groups) => {
  for (let group in groups) {
    utils.createOptGroup(dropdown, group, groups[group]);
  }
};

const populateUnitDropdown = (category, units) => {
  let dropdown = null;

  if (unitDropdowns[category]) {
    dropdown = document.getElementById(unitDropdowns[category]);
    if (dropdown.querySelectorAll('option').length === 1) {
      addOptions(dropdown, units);
    }
  }
};

const addLooseFunds = (category, funds) => {
  const looseFunds = funds.filter(function(obj) {
    return !obj.categories[1];
  });

  if (fundDropdowns[category]) {
    addOptions(document.getElementById(fundDropdowns[category]), looseFunds);
  }
};

const getGroups = (funds) => {
  let groups = {};

  funds.forEach(function(obj) {
    const group = obj.categories[obj.categories.length - 1];

    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(obj);
  });
  return groups;
};

const addGroupedFunds = (category, funds) => {
  const groupedFunds = funds.filter(function(obj) {
    return obj.categories[1];
  });

  const groups = getGroups(groupedFunds);
  addGroupedOptions(document.getElementById(fundDropdowns[category]), groups);
};

const populateFundDropdown = (category, funds) => {
  if (fundDropdowns[category]) {
    addLooseFunds(category, funds);
    addGroupedFunds(category, funds);
  }
};

const getFunds = (categories) => {
  categories.forEach(function(obj) {
    const unit = obj.categories.length > 2 ? obj.categories[obj.categories.length - 2] : '';
    const area = obj.categories[obj.categories.length - 1];

    if (area) {
      // if (!designationFunds[`${unit}-${area}`]) {
      //   designationFunds[`${unit}-${area}`] = [];
      // }
      // designationFunds[`${unit}-${area}`].push(obj);
      if (!designationFunds[area]) {
        designationFunds[area] = [];
      }
      designationFunds[area].push(obj);
    }
  });

  for (let funds in designationFunds) {
    if (Array.isArray(designationFunds[funds])) {
      designationFunds[funds].sort(utils.dynamicSort('name'));
    }
  }
};

const getAreas = (category, units) => {
  units.forEach(function(unit) {
    designationAreas[unit] = [];

    const hasArea = categoryGroups[category].filter(function(obj) {
      return obj.categories[2] && obj.categories[0] !== featuredFunds && obj.categories[0] !== priorityFunds;
    });

    hasArea.forEach(function(obj) {
      const area = obj.categories[2];

      if (obj.categories[1] === unit && designationAreas[unit].indexOf(area) === -1) {
        designationAreas[unit].push(area);
      }
    });

    if (Array.isArray(designationAreas[unit])) {
      designationAreas[unit].sort();
    }
  });
};

const getUnits = (category, categories) => {
  designationUnits[category] = [];
  categories.forEach(function(obj) {
    const unit = obj.categories[1];

    if (unit && designationUnits[category].indexOf(unit) === -1) {
      designationUnits[category].push(unit);
    }
  });

  if (Array.isArray(designationUnits[category])) {
    designationUnits[category].sort();
    populateUnitDropdown(category, designationUnits[category]);
    getAreas(category, designationUnits[category]);
  }
};

function showFunds(dropdown) {
  const dropdownValue = dropdown.value;
  let nextField;
  let designationType = designationAreas[dropdownValue] ? (designationAreas[dropdownValue].length > 0 ? 'area' : '') : '';

  designationType = designationType || (designationFunds[dropdownValue] ? (designationFunds[dropdownValue].length > 0 ? 'fund' : '') : '');

  if (utils.hasClass(dropdown, 'unit-select')) {
    selectedUnit = utils.getSelectedOptionValue(dropdown);
  }

  if (designationType === 'area' && !utils.hasClass(dropdown, 'area-select')) {
    hideFunds();
    nextField = utils.getClosestEl(dropdown, constants.selectors.fieldSelector).nextElementSibling;
    utils.createDropdown(nextField, nextField.querySelector('select'), designationAreas[dropdownValue]);
    setTimeout(() => {
      validation.fv.resetField(nextField.querySelector('select').name, true);
      utils.showElement(nextField);
    }, 250);
  } else if (designationType === 'fund' || utils.hasClass(dropdown, 'area-select')) {
    if (designationAreas[selectedUnit]) {
      if (designationAreas[selectedUnit].length > 0) {
        hideFunds('fund');
      } else {
        hideFunds('area');
      }
    }
    nextField = utils.getClosestEl(dropdown, constants.selectors.fieldSelector).nextElementSibling.nextElementSibling || utils.getClosestEl(dropdown, constants.selectors.fieldSelector).nextElementSibling;
    utils.createDropdown(nextField, nextField.querySelector('select'), designationFunds[dropdownValue]);
    setTimeout(() => {
      validation.fv.resetField(nextField.querySelector('select').name, true);
      utils.showElement(nextField);
    }, 250);
  }
}

function hideFunds(type) {
  const allNotFeaturedSubFunds = Array.prototype.slice.call(giftAreas).concat(Array.prototype.slice.call(giftFunds)).filter(function(el) {
    return el.id !== 'fundSelectFeatured';
  });

  const notFeaturedFunds = Array.prototype.slice.call(giftFunds).filter(function(el) {
    return el.id !== 'fundSelectFeatured';
  });

  const els = type ? (type === 'area' ? giftAreas : notFeaturedFunds) : allNotFeaturedSubFunds;

  els.forEach(function(el) {
    utils.hideElement(utils.getClosestEl(el, constants.selectors.fieldSelector));
    utils.selectOption(el, '');
  });
}

function clearUnits() {
  giftUnits.forEach(function(el) {
    utils.selectOption(el, '');
  });
}

function clearSpecifiedFund() {
  document.getElementById('specify-fund').value = '';
  if (document.getElementById('is-specify-fund').checked) {
    triggerEvent(document.getElementById('is-specify-fund-trigger'), 'click');
  }
  utils.hideElement(document.getElementById('specify-fund-checkbox'));
}

const handleFundChange = (e) => {
  hideGiftLevels();
  if (e.target.value !== '') {
    showFunds(e.target);
  }
};

const fundEvents = () => {
  if (giftUnits.length > 0 && giftAreas.length > 0) {
    Array.prototype.slice.call(giftUnits).concat(Array.prototype.slice.call(giftAreas)).forEach(function(el) {
      el.addEventListener('change', handleFundChange);
    });


    Array.prototype.slice.call(giftFunds).concat(options.search ? Array.prototype.slice.call(searchFunds) : []).forEach(function(el) {
      el.addEventListener('change', function(e) {
        const selectedFund = utils.getSelectedOptionValue(e.currentTarget);
        const redirectUrl = utils.getSelectedOptionRedirect(e.currentTarget)

        if (redirectUrl) {
          utils.redirect(redirectUrl);
        } else {
          maybeGiftLevels(selectedFund);
        }
      });
    });
  }
};

const processDesignations = (data) => {
  getAllDesignations(data).then(() => {
    allDesignations = allDesignations.concat(featuredDesignations);
    cleanData();
    getDisplayDesignations();
    getDesignationCategories();
    getCategoryGroups();
    for (let category in categoryGroups) {
      if (category !== featuredFunds && category !== priorityFunds) {
        getUnits(category, categoryGroups[category]);
      }
      getFunds(categoryGroups[category]);
      if (category === featuredFunds || category === priorityFunds) {
        populateFundDropdown(category, categoryGroups[category]);
      }
    }

    fundEvents();
    if (options.search) {
      search.init();
    }
    if (options.preselect) {
      preSelectCategory();
      preSelectDonation();
    }
    if (options.tribute) {
      maybeTribute();
    }
  });
}

const funds = (restore) => {
  const successCallback = (data) => {
    console.log(data);
    localStorage.setItem('funds', JSON.stringify(data));
    processDesignations(data);
    setTimeout(() => {
      ui.showPage();
    }, 500);
  };

  const failureCallback = (errors) => {
    console.log(errors);
  };

  if (restore && localStorage.getItem('funds')) {
    processDesignations(JSON.parse(localStorage.getItem('funds')));
  } else if (typeof BLACKBAUD !== 'undefined') {
    const queryService = new BLACKBAUD.api.QueryService();
    if (queryService) {
      queryService.getResults(guids.query, successCallback, failureCallback);
    }
  } else {
    successCallback({
      "Fields": [{
          "Name": "BBIS Is Active Attribute\\Value",
          "Caption": "BBIS Is Active Attribute\\Value"
        },
        {
          "Name": "Alternate Lookup IDs\\Lookup ID",
          "Caption": "Alternate Lookup IDs\\Lookup ID"
        },
        {
          "Name": "System record ID",
          "Caption": "System record ID"
        },
        {
          "Name": "Public name",
          "Caption": "Public name"
        },
        {
          "Name": "BBIS Category Attribute\\Value",
          "Caption": "BBIS Category Attribute\\Value"
        },
        {
          "Name": "BBIS Display Name Attribute\\Value",
          "Caption": "BBIS Display Name Attribute\\Value"
        },
        {
          "Name": "BBIS Featured Fund Attribute\\Value",
          "Caption": "BBIS Featured Fund Attribute\\Value"
        },
        {
          "Name": "BBIS Keywords Attribute\\Value",
          "Caption": "BBIS Keywords Attribute\\Value"
        },
        {
          "Name": "BBIS Levels Attribute\\Value",
          "Caption": "BBIS Levels Attribute\\Value"
        },
        {
          "Name": "BBIS URL Redirect Attribute\\Value",
          "Caption": "BBIS URL Redirect Attribute\\Value"
        }
      ],
      "Rows": [{
          "Values": [
            "True",
            "01372",
            "4e2b93c8-7a7e-46ff-bb5d-c126db26ac27",
            "Learning Enhancement Center Special Projects",
            "Campus Life>Faculty and Academic Programs",
            "",
            "",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "01512",
            "b4f3c086-2a45-49ae-bb79-78e736b09c74",
            "President's Scholars General Support",
            "Campaign Priorities>Community",
            "",
            "",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "01584",
            "fa953737-3e37-430d-aa06-5afe0ec1ed64",
            "Presidential Fund for Immediate Needs",
            "Campus Life>SMU General",
            "",
            "True",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "11014",
            "d5aab732-ef06-4300-a690-e05fa2a89b7b",
            "Mustang Athletic Fund",
            "Campus Life>Athletics",
            "",
            "",
            "",
            "",
            "https://smumustangs.evenue.net/www/ev_smu-athletics/ss/ev/DonationEntry?donationCd=MAF&siteId=ev_smu-athletics&locale=en_US&linkID=smu-athletics"
          ]
        },
        {
          "Values": [
            "True",
            "11069",
            "a301796d-99f1-416d-913b-0e8695c8a6d9",
            "Mustang Excellence Fund for Women's Soccer",
            "Campus Life>Athletics",
            "",
            "",
            "",
            "",
            "https://smumustangs.evenue.net/www/ev_smu-athletics/ss/ev/DonationEntry?donationCd=EXF&siteId=ev_smu-athletics&locale=en_US&linkID=smu-athletics"
          ]
        },
        {
          "Values": [
            "True",
            "11162",
            "bcaeee8e-00db-4dd5-8bfd-ef766c530d2e",
            "Athletics Fund for Immediate Needs",
            "Campus Life>Athletics",
            "",
            "True",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "21413",
            "878394ef-97e9-4858-9dc5-5359b321e827",
            "SMU Human Rights Program - Triumph of the Spirit Award",
            "Campus Life>Faculty and Academic Programs",
            "Triumph of the Spirit",
            "",
            "",
            "Visionary $50,000\nRecognition as Visionary Sponsor on all\nevent-related printed materials\nOpportunity to include a paragraph and logo\nin the printed program\nRecognition on the Human Rights Program website and social media networks\nRecognition from the podium and in\nPowerpoint at event\nTable of Ten with preferred placement plus another Table of Ten given to past honorees\nOpportunity to meet honorees in\nVIP Reception- entry for ten\n$50,000.00 ea.\n\n0\nPhotography/Video OR VIP Photo Booth Sponsor: $3,500\nRecognition from the podium as well as in Powerpoint at the event for the underwriting of the video and photography or the photo booth with recognition on each photo strip\n2 Tickets at event\n$3,500.00 ea.\n\n0\nDefender: $25,000\nRecognition as Defender Sponsor on all\nevent-related printed materials\nRecognition on the Human Rights Program website and social media networks\nRecognition from the podium and in\nPowerpoint at event\nTable of Ten with preferred placement\nplus five seats given to students\nEntry for ten to VIP Reception\n$25,000.00 ea.\n\n0\nSupporter: $10,000\nRecognition as Supporter Sponsor in printed program and in Powerpoint at event\nTable of Ten plus two seats given to community Human Rights partners\nEntry for four to VIP Reception\n$10,000.00 ea.\n\n0\nAdvocate: $5,000\nRecognition in Advocate Sponsor in printed program and in Powerpoint at event\nTable of Ten\nEntry for two to VIP Reception\n$5,000.00 ea.\n\n0\nCommunity Leader: $2,500\nRecognition in Powerpoint at event\nTable of Ten\n$2,500.00 ea.",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "31021",
            "0e4a058a-41a9-4b99-b1bf-d7c944b3a4b7",
            "Dance Division",
            "Academics>SMU Meadows School of the Arts>Dance",
            "",
            "",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "31029",
            "b5ae37b1-ecb0-4504-ab60-550a2936ebe3",
            "Division of Music",
            "Academics>SMU Meadows School of the Arts>Music",
            "Music at Meadows",
            "",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "31244",
            "0e8bea79-721d-4cb1-bff0-b9891ba530c7",
            "Advertising Chair Restricted Fund",
            "",
            "",
            "",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "31355",
            "b20df4df-ccf8-45bc-8e5c-20e22719ab34",
            "Chamber Music",
            "Academics>SMU Meadows School of the Arts>Music",
            "",
            "False",
            "",
            "",
            ""
          ]
        },
        {
          "Values": [
            "True",
            "51156",
            "968dbacb-048d-454a-8a6a-fecdd199ff8f",
            "Lyle Undergraduate Engineering Scholars Program",
            "Campus Life>Scholarships",
            "Lyle Scholars",
            "",
            "",
            "",
            ""
          ]
        }
      ]
    });
  }
};

function getPreSelectedDesignation(designationId) {
  const isGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  let designation = null;

  if (isGuid.test(designationId)) {
    designation = allDesignations.filter(function(obj) {
      return obj.guid.toLowerCase() === designationId.toLowerCase();
    });
  } else {
    designation = allDesignations.filter(function(obj) {
      const lookupId = (obj.lookupId || obj.altLookupId).toLowerCase();

      return lookupId === designationId.toLowerCase();
    });
  }
  return designation[0];
}

export const getPreSelectedData = () => {
  const preSelectedDesignation = document.getElementById('jsPreselectedFund');
  if (preSelectedDesignation) {
    return JSON.parse(preSelectedDesignation.dataset.designation);
  }
}

export const togglePreSelectedFundOnly = (mode) => {
  if (mode === 'show') {
    utils.hideElement(document.getElementById('userSelected'));
    utils.showElement(document.getElementById('preSelected'));
  } else {
    utils.hideElement(document.getElementById('preSelected'));
    utils.showElement(document.getElementById('userSelected'));
  }
};

function showPreSelectedDesignation(designationId, editStatus, removeStatus) {
  let designation = getPreSelectedDesignation(designationId);

  if (designation) {
    const preSelectedFund = document.getElementById('jsPreselectedFund');

    if (preSelectedFund) {
      preSelectedFund.textContent = designation.name;
      designation.editStatus = editStatus;
      designation.removeStatus = removeStatus;
      preSelectedFund.dataset.designation = JSON.stringify(designation);
      togglePreSelectedFundOnly('show');
    }
  }
}

const addPreSelectedGiftRow = (preSelectedGift) => {
  const giftFundCategory = fundCategories[preSelectedGift.designation.categories[0]] ? fundCategories[preSelectedGift.designation.categories[0]] : '';
  const giftFundUnit = preSelectedGift.designation.categories[1] ? preSelectedGift.designation.categories[1] : '';
  const giftFundArea = preSelectedGift.designation.categories[2] ? preSelectedGift.designation.categories[2] : '';
  const giftFundId = preSelectedGift.designation.guid;
  const giftFundText = preSelectedGift.designation.name;
  const giftAmount = preSelectedGift.amount === 0 ? '' : utils.numToFloat(preSelectedGift.amount, 2);
  const editStatus = preSelectedGift.edit;
  const removeStatus = preSelectedGift.remove;
  const giftRowsContainers = document.querySelectorAll('.gifts');
  let giftRowIndex = gifts.updateGiftRowIndex();

  const increaseOnly = (e, minAmount) => {
    if (e.target.value < minAmount || isNaN(parseFloat(e.target.value))) {
      e.target.value = minAmount;
    }
  };

  giftRowsContainers.forEach(function(el, index) {
    let clone = document.getElementById('giftRowTemplate').content.cloneNode(true);
    const amountField = clone.querySelector('.amount');
    const editButton = clone.querySelector('.btn-edit');
    const removeButton = clone.querySelector('.btn-remove');

    clone.firstElementChild.setAttribute('data-row-index', giftRowIndex);
    clone.querySelector('.gift-name').textContent = giftFundText;
    amountField.value = giftAmount;
    amountField.name = `amount-${giftRowIndex}-${index}`;
    clone.querySelector('.gift-category').value = giftFundCategory;
    clone.querySelector('.gift-category').name = `gift-${giftRowIndex}-${index}`;
    clone.querySelector('.gift-unit').value = giftFundUnit;
    clone.querySelector('.gift-unit').name = `gift-unit-${giftRowIndex}-${index}`;
    clone.querySelector('.gift-area').value = giftFundArea;
    clone.querySelector('.gift-area').name = `gift-area-${giftRowIndex}-${index}`;
    clone.querySelector('.gift-fund').value = giftFundId;
    clone.querySelector('.gift-fund').name = `gift-fund-${giftRowIndex}-${index}`;
    clone.querySelector('.fund-search').value = giftFundCategory === '' ? true : false;
    clone.querySelector('.fund-search').name = `fund-search-${giftRowIndex}-${index}`;
    clone.querySelector('.fund-specified').value = false;
    clone.querySelector('.fund-specified').name = `fund-specified-${giftRowIndex}-${index}`;

    if (editStatus === '1') {
      utils.hideElement(editButton);
      amountField.addEventListener('change', e => {
        increaseOnly(e, giftAmount);
      });
    } else if (editStatus === '2') {
      utils.hideElement(editButton);
      amountField.disabled = true;
    }
    if (removeStatus === '1') {
      utils.hideElement(removeButton);
    }
    // Add row
    el.querySelector('.list-group').append(clone);
    // Dynamic validation for amount field
    validation.addAmountValidator(`amount-${giftRowIndex}-${index}`);
  });
  gifts.updateGiftAmountTotal();
  gifts.initGiftActions();

  // Save form state
  persistence.saveForm('1');
  // Go to next panel
  if (!urlParams.quickForm) {
    validation.fv.plugins.wizard.currentStep = 1;
    validation.panelValid.index = 1;
    window.dispatchEvent(validation.panelValid);
  }
};

export const addPreSelectedDonation = (designationId, amount, edit, remove) => {
  let preSelectedGift = {};
  let designation = getPreSelectedDesignation(designationId);

  if (designation) {
    preSelectedGift.designation = designation;
    preSelectedGift.amount = Number(amount);
    preSelectedGift.edit = edit;
    preSelectedGift.remove = remove;
    addPreSelectedGiftRow(preSelectedGift);
    return true;
  } else {
    return false;
  }
};

const preSelectDonation = () => {
  const hasParams = () => {
    return searchParams.has('f');
  };

  if (hasParams() && !state.isReturning) {
    // if (hasParams()) {
    const preSelectedDesignations = searchParams.getAll('f');
    const preSelectedAmounts = searchParams.getAll('amount');
    const editStatus = searchParams.get('e');
    const removeStatus = searchParams.get('r');
    const appeal = searchParams.get('appeal') || '';
    let giftCount = 0;

    if (appeal !== '') {
      document.getElementById('appealId').value = appeal;
      getAppeals(appeal);
    }
    if (preSelectedDesignations.length > 0) {
      if (preSelectedDesignations.length === preSelectedAmounts.length) {
        preSelectedDesignations.forEach(function(designationId, index) {
          if (addPreSelectedDonation(designationId, preSelectedAmounts[index], editStatus, removeStatus)) {
            giftCount++;
          }
        });
        if (giftCount > 0) {
          //displayNextStep();
        }
      } else if (preSelectedDesignations.length === 1 && preSelectedAmounts.length === 0) {
        state.preselectedFundOnly = true;
        showPreSelectedDesignation(preSelectedDesignations[0], editStatus, removeStatus);
      }
    }
  }
};

const preSelectCategory = () => {
  function selectCategory(dropdown, val) {
    utils.selectOptionByText(dropdown, val);
    setTimeout(function() {
      utils.triggerEvent(dropdown, 'change');
    }, 100);
  }

  const hasParams = function() {
    return searchParams.has('c');
  };

  if (hasParams()) {
    const category = categoryIDs[searchParams.get('c')];
    const main = document.getElementById('main');

    if (category) {
      const categories = category.category.split('>');
      let backgroundImage = category.backgroundImage;
      const headerText = category.headerText;

      if (categories[0] === 'Campus Life') {
        document.getElementById('campusTab').click();
        selectCategory(document.getElementById(unitDropdowns[campusFunds]), categories[1]);
        if (categories.length === 3) {
          setTimeout(function() {
            selectCategory(document.getElementById('areaSelectCampus'), categories[2]);
          }, 100);
        }
      } else {
        selectCategory(document.getElementById(unitDropdowns[academicFunds]), categories[1]);
        if (categories.length === 3) {
          setTimeout(function() {
            selectCategory(document.getElementById('areaSelectAcademics'), categories[2]);
          }, 100);
        }
      }
      if (backgroundImage !== '') {
        backgroundImage = `url(${decodeURI(backgroundImage).trim().replace(/\\/g, '')})`;
        main.style.setProperty('--bg-image', backgroundImage);
        main.classList.add('has-background-image');
      }
      if (headerText !== '') {
        document.querySelector('.header .department-text').textContent = headerText;
      }
    }
  }
};