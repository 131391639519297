const classes = {
  activeClass: 'active',
  hiddenClass: 'd-none',
}

const elements = {
  adf: document.getElementById('adf'),
  amountBtns: document.querySelectorAll('[name="gift-amount"]'),
  bbForm: document.getElementById('form1'),
  btnBack: document.getElementById('btnBack'),
  btnCancel: document.getElementById('btnCancel'),
  btnNext: document.getElementById('btnNext'),
  gifts: document.getElementById('gifts'),
  giftsConfirm: document.getElementById('giftsConfirm'),
  navPills: document.getElementById('navPills'),
  otherAmount: document.getElementById('otherAmount'),
  page: document.getElementById('page'),
  panels: document.querySelectorAll('.panel'),
  panelBtns: document.querySelectorAll('#navPills .nav-link'),
  searchFundResults: document.getElementById('fund-search-results'),
  specifyFund: document.getElementById('specify-fund'),
  summary: document.getElementById('summary'),
};

const panels = [{
    id: 'giftInfo',
    backBtn: false,
    cancelBtn: true,
    completedText: 'Add Gift',
    nextButtonHtml: '<span>Next</span><span></span>',
  },
  {
    id: 'giftReview',
    backBtn: false,
    cancelBtn: false,
    completedText: '',
    nextButtonHtml: '<span>Next</span><span>: Your Info</span>',
  },
  {
    id: 'yourInfo',
    backBtn: true,
    cancelBtn: false,
    completedText: '',
    nextButtonHtml: '<span>Next</span><span>: Confirm</span>',
  },
  {
    id: 'confirm',
    backBtn: false,
    cancelBtn: false,
    completedText: '',
    nextButtonHtml: '<span>Make Payment</span><span></span>',
  },
];

const selectors = {
  fieldSelector: '.row',
  nextButton: '#btnNext',
  panelSelector: '.panel',
  prevButton: '#btnBack',
}

export {
  classes,
  elements,
  panels,
  selectors,
};