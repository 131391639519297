export const options = {
  btnBack: true,
  btnCancel: true,
  giftLevels: true,
  multiStep: true,
  otherAmount: true,
  payroll: true,
  preselect: true,
  recurrence: true,
  search: true,
  specifyFund: true,
  tribute: true,
};