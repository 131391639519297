import * as constants from "./constants";
import { getGiftFrequency } from "./recurrence";
import * as utils from "./utils";

export const updateSummary = () => {
  const isInternational = utils.hasClass(document.getElementById('intlFieldsTab'), constants.classes.activeClass);

  document.getElementById('jsFrequency').innerText = getGiftFrequency();
  document.getElementById('jsPayment').innerText = document.querySelector('[name="payment-method"]:checked').nextElementSibling.innerText;
  document.getElementById('jsFirstName').innerText = document.getElementById('firstName').value;
  document.getElementById('jsLastName').innerText = document.getElementById('lastName').value;
  document.getElementById('jsAddress').innerText = isInternational ? document.getElementById('intlAddress').value : document.getElementById('address').value;
  document.getElementById('jsCity').innerText = isInternational ? document.getElementById('intlCity').value : document.getElementById('city').value;
  document.getElementById('jsState').innerText = isInternational ? document.getElementById('province').value || utils.getSelectedOptionText(document.getElementById('provinceSelect')) : utils.getSelectedOptionValue(document.getElementById('state'));
  document.getElementById('jsZip').innerText = isInternational ? document.getElementById('intlZip').value : document.getElementById('zip').value;
  document.getElementById('jsCountry').innerText = isInternational ? utils.getSelectedOptionText(document.getElementById('country')) : '';
  document.getElementById('jsEmail').innerText = document.getElementById('emailAddress').value;
  document.getElementById('jsPhone').innerText = document.getElementById('phoneNumber').value;
};