import * as constants from "./constants";
import * as dayjs from 'dayjs';
import { clearStorage } from "./persistence";
import { state } from "./state";
import * as ui from "./ui";
import * as urlParams from "./urlParams";
import * as utils from "./utils";
import { disableValidator, enableValidator } from "./validation";
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const partId = document.querySelector('.BBDonationApiContainer') ? document.querySelector('.BBDonationApiContainer').dataset.partid : null;
const donationService = partId ? new BLACKBAUD.api.DonationService(partId) : null;

let bbcheckout = null;
let appealID = null;
let donation = null;
let editorContent = null;
let publicKey = null;

export const init = () => {
  if (partId) {
    if (urlParams.appeal) {
      getAppeals(urlParams.appeal);
    }
    getCountries();
    getPublicKey();
    getEditorContent();
    initCheckout();
    // if (urlParams.recur) {
    //   document.querySelector('[name="gift-recurrence"][value="' + urlParams.recur +'"]').click();
    // }
  }
};


const initBBCheckout = () => {
  bbcheckout = new SecureCheckout(handleCheckoutComplete, handleCheckoutError, handleCheckoutCancelled, handleCheckoutLoaded);
};

const initCheckout = () => {
  const paymentForm = document.getElementById('paymentForm');

  if (!paymentForm) {
    let newForm = document.createElement('form');

    utils.setAttributes(newForm, {
      'method': 'get',
      'id': 'paymentForm'
    });
    newForm.dataset.formtype = 'bbCheckout';
    newForm.setAttribute('data-disable-submit', 'false');
    newForm.novalidate = true;
    document.body.appendChild(newForm);
  }

  initBBCheckout();
};

const getAppeals = (appeal) => {
  const queryService = new BLACKBAUD.api.QueryService();
  const appealIdInput = document.getElementById('appealId');

  const success = (data) => {
    const matchedAppeal = data.Rows.filter(obj => {
      return obj.Values[0] === 'True' && obj.Values[1] === appeal;
    });

    if (matchedAppeal.length > 0) {
      appealID = matchedAppeal[0].Values[2];
      if (appealIdInput) {
        appealIdInput.value = appeal;
      }
    }
  };

  const error = (errors) => {
    console.log(errors);
    return false;
  };

  queryService.getResults(guids.appeals, success, error);
};

const getCountries = () => {
  const countryService = new BLACKBAUD.api.CountryService();
  const countrySelect = document.getElementById('country');

  const handleCountryChange = e => {
    const provinceTextControl = document.querySelector('.is-text');
    const provinceText = document.getElementById('province');
    const provinceSelectControl = document.querySelector('.is-select');
    const provinceSelect = document.getElementById('provinceSelect');

    countryService.getStates(e.currentTarget.value, function(states) {
      utils.clearOptions(provinceSelect);
      utils.clearValue(provinceText);

      if (states.length > 0) {
        states.forEach(function(state) {
          utils.createISOOption(provinceSelect, state);
        });
        utils.hideElement(provinceTextControl);
        utils.showElement(provinceSelectControl);
      } else {
        utils.hideElement(provinceSelectControl);
        utils.showElement(provinceTextControl);
      }
    });

    // Re-initialize phone validator based on country
    if (utils.getSelectedOptionAttribute(e.currentTarget, 'iso') !== 'US') {
      disableValidator('phoneNumber', 'regexp');
    } else {
      enableValidator('phoneNumber', 'regexp');
    }
  };

  countryService.getCountries(function(countries) {
    countries.forEach(function(country) {
      utils.createISOOption(countrySelect, country);
    });
  });

  countrySelect.addEventListener('change', handleCountryChange);
};

const getPublicKey = () => {
  const onPublicKeySuccess = (data) => {
    publicKey = JSON.parse(data.Data).PublicKey;
  };

  const onPublicKeyFailure = (errors) => {
    console.log(errors);
  };

  donationService.getCheckoutPublicKey(onPublicKeySuccess, onPublicKeyFailure);
};

const getEditorContent = () => {
  const onEditorContentSuccess = (content) => {
    editorContent = content;
  };

  const onEditorContentFailure = (errors) => {
    console.log(errors);
  };

  donationService.getADFEditorContentInformation(partId, onEditorContentSuccess, onEditorContentFailure);
};

const getTotalGiftAmount = () => {
  const selectedFunds = document.getElementById('gifts').querySelectorAll('.gift');
  let totalGiftAmount = 0;

  if (selectedFunds.length > 0) {
    selectedFunds.forEach(fund => {
      totalGiftAmount += parseFloat(fund.querySelector('.amount').value);
    });
  }
  return totalGiftAmount;
};

const handleCheckoutComplete = (e, token) => {
  console.log(e);
  console.log(token);

  const handlePaymentComplete = function(data) {
    // const savedWriteInFund = sessionStorage.getItem('otherfundName');
    // const writeInFundPlaceholder = /VERIFY DESIGNATION/;
    showConfirmation(JSON.parse(data.Data).confirmationHTML);
  };

  const handleDonationCreateFailed = function(errors) {
    console.log(errors);
  };

  ui.hidePage();
  donation.TokenId = e.detail.transactionToken;
  donationService.checkoutDonationComplete(donation, handlePaymentComplete, handleDonationCreateFailed);
  return false;
};

const handleCheckoutError = (data) => {
  console.log(data);
};

const handleCheckoutCancelled = (data) => {
  try {
    donationService.checkoutDonationCancel(data, onSuccess, onFail);
  } catch (err) {
    // nothing to see here...
  }
  console.log(data);
};

const handleCheckoutLoaded = (data) => {
  console.log(data);

  const handleDonationCreated = (data) => {
    console.log(data);
  };

  const handleDonationCreateFailed = (errors) => {
    console.log(errors);
  };

  const tokenField = document.querySelector('[name="transactiontoken"]');

  if (tokenField) {
    donation.TokenId = tokenField.value;
    donationService.checkoutDonationCreate(donation, handleDonationCreated, handleDonationCreateFailed);
  }
  return false;
};

const makePayment = () => {
  var recurrence = false;

    if (document.getElementById('monthly').checked ||
    document.getElementById('quarterly').checked ||
    document.getElementById('annually').checked ||
    document.getElementById('fourweek').checked) {
      recurrence = true; 
  }

  let checkoutData = {
    key: publicKey,
    Amount: getTotalGiftAmount(),
    UseCaptcha: editorContent.RecaptchRequired,
    BillingAddressCity: donation.Donor.Address.City,
    BillingAddressCountry: donation.Donor.Address.Country,
    BillingAddressLine: donation.Donor.Address.StreetAddress,
    BillingAddressPostCode: donation.Donor.Address.PostalCode,
    BillingAddressState: donation.Donor.Address.State,
    BillingAddressEmail: donation.Donor.EmailAddress,
    BillingAddressFirstName: donation.Donor.FirstName,
    BillingAddressLastName: donation.Donor.LastName,
    Cardholder: donation.Donor.FirstName + ' ' + donation.Donor.LastName,
    ClientAppName: 'GiveNow',
    MerchantAccountId: editorContent.MerchantAccountID,
    IsEmailRequired: true,
    PrimaryColor: editorContent.PrimaryFontColor,
    SecondaryColor: editorContent.SecondaryFontColor,
    FontFamily: editorContent.FontType,
    IsNameVisible: true,
    UseVisaCheckout: editorContent.UseVisaPass && !recurrence,
    UseMasterpass: editorContent.UseMasterPass && !recurrence,
    UseApplePay: editorContent.UseApplePay && !recurrence,
    UseApplePayDonateButton: false,
  };


  if (donation.Gift && donation.Gift.Recurrence) {
    checkoutData.CardToken = editorContent.DataKey;
    if (donation.Gift.Recurrence.ProcessNow) {
      return bbcheckout.processCardNotPresent(checkoutData);
    } else {
      return bbcheckout.processStoredCard(checkoutData);
    }
    console.log("ProcessNow: " + donation.Gift.Recurrence.ProcessNow + " StartDate: " + donation.Gift.Recurrence.StartDate + " EndDate: " + donation.Gift.Recurrence.EndDate);
    
  } else if (donation.Gift && donation.Gift.PaymentMethod === '0') {
    return bbcheckout.processCardNotPresent(checkoutData);
  }
  
};

const showConfirmation = (html) => {
  const confirmation = document.getElementById('confirmation');

  // Display confirmation HTML
  confirmation.innerHTML = html;
  utils.hideElement(constants.elements.adf);
  utils.showElement(confirmation);
  ui.showPage();
  // theConfirmation.innerHTML = savedWriteInFund ? confirmationHTML.replace(writeInFundPlaceholder, savedWriteInFund) : confirmationHTML;
  // sessionStorage.removeItem('otherfundName');

  // Remove saved data
  clearStorage();
};

const getDonation = () => {
  const isInternational = utils.hasClass(document.getElementById('intlFieldsTab'), constants.classes.activeClass);
  const paymentMethod = utils.getPaymentMethod();
  let designations = null;
  let tribute = null;
  let recurrence = null;
  let writeIn = false;
 // let declinedBenefit = false;
  let writeInName = '';
  
  donation = {
    Donor: {
      FirstName: document.getElementById('firstName').value,
      LastName: document.getElementById('lastName').value,
      EmailAddress: document.getElementById('emailAddress').value,
      Phone: document.getElementById('phoneNumber').value,
      Address: {
        StreetAddress: isInternational ? document.getElementById('intlAddress').value : document.getElementById('address').value,
        City: isInternational ? document.getElementById('intlCity').value : document.getElementById('city').value,
        State: isInternational ? document.getElementById('province').value || utils.getSelectedOptionText(document.getElementById('provinceSelect')) : utils.getSelectedOptionValue(document.getElementById('state')),
        Country: isInternational ? utils.getSelectedOptionText(document.getElementById('country')) : 'United States',
        PostalCode: isInternational ? document.getElementById('intlZip').value : document.getElementById('zip').value,
      }
    },
    Gift: {
      PaymentMethod: paymentMethod,
      // IsAnonymous: document.getElementById('isAnonymous').checked,
      IsCorporate: document.getElementById('isCorporate').checked,
    },
    Origin: {
      PageId: BLACKBAUD.api.pageInformation.pageId,
      PartId: partId
    },
    PartId: partId,
    MerchantAccountId: editorContent.MerchantAccountID
  };

  // Credit card payment needs BBSPReturnUri
  if (paymentMethod === '0') {
    donation.BBSPReturnUri = window.location.href;
  }

  // Comments
  if (document.getElementById('additionalInfo').checked && document.getElementById('comments').value !== '') {
    donation.Gift.Comments = document.getElementById('comments').value;
  }

  // Add designations
  const getDesignations = () => {
    let designations = [];
    let gift;

    document.querySelectorAll('#gifts .gift').forEach(fund => {
      gift = {};
      gift.Amount = parseFloat(fund.querySelector('.amount').value);
      gift.DesignationId = fund.querySelector('.gift-fund').value;
      designations.push(gift);

      if (fund.querySelector('.fund-specified').value === 'true') {
        writeIn = true;
        writeInName = fund.querySelector('.gift-name').textContent;
      }
    });

    return designations;
  };

  designations = getDesignations();
  if (designations.length > 0) {
    donation.Gift.Designations = designations;
  }

  // Add attributes
  const getAttributes = () => {
    let attributes = [];
    let giftInTakeDate = {};
    let writeInAttribute = {};
  //  let declinedBenefitAttribute = {};

    const createAttribute = (el, val) => {
      let attribute = {};

      attribute.AttributeId = el.getAttribute('data-guid');
      attribute.Value = val ? val : el.getAttribute('data-attribute-value') || el.value;
      return attribute;
    };

    document.querySelectorAll('.is-attribute').forEach(el => {
      const isActive = (el.type.toLowerCase() === 'checkbox' || el.type.toLowerCase() === 'radio') ? el.checked : el.value !== '';

      if (isActive) {
        attributes.push(createAttribute(el));
      }
    });

    if (writeIn) {
      writeInAttribute.AttributeId = guids.writeInFund;
      writeInAttribute.Value = writeInName;

      attributes.push(writeInAttribute);
    }
/*
    if (declinedBenefit) {
      declinedBenefitAttribute.AttributeId = guids.declinedBenefit;
      declinedBenefitAttribute.value = declinedBenefit;

      attributes.push(declinedBenefitAttribute);

    }

       */
    giftInTakeDate.AttributeId = guids.giftInTakeDate;
    giftInTakeDate.Value = new Date();
    attributes.push(giftInTakeDate);

    return attributes;
  };

  let attributes = getAttributes();
  if (attributes.length > 0) {
    donation.Gift.Attributes = attributes;
  }

  const getTribute = () => {
    let tributeeInfo = {
      AttributeId: guids.tributeTributee,
      Value: ''
    };
    let acknowledgeeInfo = {
      AttributeId: guids.tributeInfoAcknowledgee,
      Value: ''
    };
    donation.Gift.Attributes = donation.Gift.Attributes || [];

    let tribute = {
      TributeDefinition: {},
      Acknowledgee: {}
    };

    tribute.TributeDefinition.FirstName = document.getElementById('honoreeFirstName').value;
    tribute.TributeDefinition.LastName = document.getElementById('honoreeLastName').value;
    tribute.TributeDefinition.Type = document.querySelector('[name="tribute-type"]:checked').value;

    tributeeInfo.Value = `${tribute.TributeDefinition.FirstName} ${tribute.TributeDefinition.LastName}`;

    if (document.getElementById('notify').checked) {
      tribute.TributeDefinition.Description = state.isTribute ? trim(document.getElementById('tributeName').textContent) : document.getElementById('notifyDisplayName').value;
      tribute.Acknowledgee.FirstName = document.getElementById('notifyFirstName').value;
      tribute.Acknowledgee.LastName = document.getElementById('notifyLastName').value;
      tribute.Acknowledgee.AddressLines = document.getElementById('notifyAddress').value;
      tribute.Acknowledgee.City = document.getElementById('notifyCity').value;
      tribute.Acknowledgee.State = document.getElementById('notifyState').value;
      tribute.Acknowledgee.PostalCode = document.getElementById('notifyZip').value;
      acknowledgeeInfo.Value = `${tribute.Acknowledgee.FirstName} ${tribute.Acknowledgee.LastName}/${tribute.Acknowledgee.AddressLines}, ${tribute.Acknowledgee.City}, ${tribute.Acknowledgee.State} ${tribute.Acknowledgee.PostalCode}/${tribute.TributeDefinition.Description}`;
      donation.Gift.Attributes.push(acknowledgeeInfo);
    } else {
      tribute.TributeDefinition.Description = state.isTribute ? trim(document.getElementById('tributeName').textContent) : `${tribute.TributeDefinition.Type} ${tribute.TributeDefinition.FirstName} ${tribute.TributeDefinition.LastName}`;
      tribute.Acknowledgee.FirstName = '~';
      tribute.Acknowledgee.LastName = '~';
      tribute.Acknowledgee.AddressLines = '~';
      tribute.Acknowledgee.City = '~';
      tribute.Acknowledgee.State = '~';
      tribute.Acknowledgee.PostalCode = '~';
    }
    tribute.Acknowledgee.Country = 'US';
    donation.Gift.Attributes.push(tributeeInfo);

    return tribute;
  };

  if (document.getElementById('isTribute').checked) {
    tribute = getTribute();
    if (tribute) {
      donation.Gift.Tribute = tribute;
    }
  }

  // Recurring gift
  const getRecurrence = () => {
    let recurrence = null;

    // https://blackbaud-bbis-prod.azurewebsites.net/bbis/reference/rest/#donationapi

    // alert("document.getElementById('monthly').checked = " + document.getElementById('monthly').checked);
    //alert("document.getElementById('annually').checked = " + document.getElementById('annually').checked + " Start date:" + document.getElementById('recurrenceStartDate').value);
    //console.log("document.getElementById('annually').checked = " + document.getElementById('annually').checked + " Start date:" + document.getElementById('recurrenceStartDate').value);

    if (document.getElementById('monthly').checked ||
      document.getElementById('quarterly').checked ||
      document.getElementById('annually').checked ||
      document.getElementById('fourweek').checked) {
      recurrence = {
        DayOfMonth: new Date().getDate(),
        StartDate: new Date(),
        Frequency: document.getElementById('monthly').value
        
      };
      
    }

   var today = new Date();
	 var dd = String(today.getDate()).padStart(2, '0');
	 var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	 var yyyy = today.getFullYear();

	 today = mm + '/' + dd + '/' + yyyy;

   var recurrstartdate = new Date();
   var recurrenddate = new Date();
   recurrstartdate =  document.getElementById('recurrenceStartDate').value;
   recurrenddate =  dayjs(recurrstartdate).add(4, 'year').format('MM/DD/YYYY');
   //console.log ('Start date: ' + recurrstartdate + ' and End Date: ' + recurrenddate);
   //recurrenddate =  document.getElementById('recurrenceEndDate').value;
   
   if (document.getElementById('monthly').checked) {
    recurrence = {
      //For consistency, use dayjs for all date related things, as it is used throughout by Zuri as well.
      DayOfMonth: dayjs().date(),
      //Convert to string without time. Date formats with times cause conversions further down to have erratic results.
      StartDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + dayjs().year().toString(),
      //Do the same with the End Date.
      EndDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + (dayjs().year() + 4).toString(),
      //Need to add 1, as the function is 0 based, but the property expects 1 based values.
      //Month: (dayjs().month() + 1),
      Frequency: document.getElementById('monthly').value
    };
  }

/*
    //add quarterly
    if (document.getElementById('quarterly').checked) {
      recurrence = {
        //For consistency, use dayjs for all date related things, as it is used throughout by Zuri as well.
        //DayOfMonth: dayjs().date(),
        DayOfMonth: dayjs(recurrstartdate).get('date'),
        //Convert to string without time. Date formats with times cause conversions further down to have erratic results.
        //StartDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + dayjs().year().toString(),
        StartDate: recurrstartdate,
        //Do the same with the End Date.
        //EndDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + (dayjs().year() + 4).toString(),
        EndDate: recurrenddate,
        //Need to add 1, as the function is 0 based, but the property expects 1 based values.
        Month: (dayjs().month() + 1),
        Frequency: document.getElementById('quarterly').value
      };
    }
*/
    //add annual
    if (document.getElementById('annually').checked) {
      recurrence = {
        //For consistency, use dayjs for all date related things, as it is used throughout by Zuri as well.
        //DayOfMonth: dayjs().date(),
        DayOfMonth: dayjs(recurrstartdate).get('date'),
        //Convert to string without time. Date formats with times cause conversions further down to have erratic results.
        //StartDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + dayjs().year().toString(),
        StartDate:  recurrstartdate,
        //Do the same with the End Date.
        //EndDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + (dayjs().year() + 5).toString(),
        EndDate: recurrenddate,
        //Need to add 1, as the function is 0 based, but the property expects 1 based values.
        //Month: (dayjs().month() + 1),
        Month: (dayjs(recurrstartdate).month() + 1),
        Frequency: document.getElementById('annually').value

      };
    }
/*
    //add every 4 weeks
    if (document.getElementById('fourweek').checked) {
      recurrence = {
         //For consistency, use dayjs for all date related things, as it is used throughout by Zuri as well.
        //DayOfMonth: dayjs().date(),
        DayOfMonth: dayjs(recurrstartdate).get('date'),
        //Convert to string without time. Date formats with times cause conversions further down to have erratic results.
        //StartDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + dayjs().year().toString(),
        StartDate:  recurrstartdate,
        //Do the same with the End Date.
        //EndDate: (dayjs().month() + 1).toString() + "/" + dayjs().date().toString() + "/" + (dayjs().year() + 5).toString(),
        EndDate: recurrenddate,
        //Need to add 1, as the function is 0 based, but the property expects 1 based values.
        //Month: (dayjs().month() + 1),
        Month: (dayjs(recurrstartdate).month()),
        Frequency: document.getElementById('fourweek').value
      };
    }*/
    
    //console.log("recurrence.Frequency = " + recurrence.Frequency + ", Month = " + recurrence.Month + " ProcessNow: " + recurrence.ProcessNow + " start date" + recurrence.StartDate);


    return recurrence;
  };

  recurrence = getRecurrence();
  if (recurrence) {
    donation.Gift.Recurrence = recurrence;
  }

  // Corporate gift
  if (donation.Gift.IsCorporate) {
    donation.Donor.OrganizationName = document.getElementById('organization').value;
  }

  // Appeal
  if (appealID) {
    donation.Origin.AppealId = appealID;
  }

  // Source code
  if (urlParams.scode) {
    donation.Gift.SourceCode = urlParams.scode;
  }

  const getNextBiWeeklyPayday = (seedDate, submitDate) => {
    const daysBetweenPayday = 14;
    let payday = seedDate;
    let paydayFound = false;

    while (!paydayFound) {
      if (dayjs(payday).isSameOrAfter(submitDate, 'day')) {
        paydayFound = true;
        payday = dayjs(payday).diff(submitDate, 'day') === 0 ? dayjs(payday).add(daysBetweenPayday, 'day') : payday = dayjs(payday);
        // payday = dayjs(payday);
      }
      payday = dayjs(payday).add(daysBetweenPayday, 'day');
    };
    return payday;
  };

  const getNextMonthlyPayday = (submitDate) => {
    const nextScheduledMonth = (dayjs().date() <= 20) ? dayjs(submitDate).add(1, 'month').month() : dayjs(submitDate).add(2, 'month').month();
    const firstDayOfMonth = dayjs().month(nextScheduledMonth).startOf('month');

    return dayjs(firstDayOfMonth);
  };

  // Payroll payment
  if (paymentMethod === '1') {
    const isBiWeekly = document.getElementById('payrollScheduleBiWeekly').checked;
    const seedDate = dayjs(seedDateBiWeekly);
    const today = urlParams.today ? dayjs(urlParams.today) : dayjs();
    const nextPayday = isBiWeekly ? getNextBiWeeklyPayday(seedDate, today) : getNextMonthlyPayday(today);

    // Recurrence fields
    donation.Gift.Recurrence = {};
    donation.Gift.Recurrence.DayOfMonth = dayjs(nextPayday).date();
    donation.Gift.Recurrence.StartDate = dayjs(nextPayday).toDate();
    donation.Gift.Recurrence.Frequency = 2;
    donation.Gift.Recurrence.ProcessNow = false;

    // Pledge fields
    donation.Gift.PledgeInstallment = {};
    donation.Gift.PledgeInstallment.InstallmentAmount = getTotalGiftAmount();
    donation.Gift.PledgeInstallment.NumberOfInstallments = 1;
  }

  
  // Process now
  if (recurrence && paymentMethod === '0') {
    donation.Gift.Recurrence.ProcessNow = true;
  }
  
};

export const processCCPayment = () => {
  getDonation();

  const onValidationSuccess = (result) => {
    makePayment();
  };

  const onValidationFailed = (errors) => {
    console.log(errors);
  };

  donationService.validateDonationRequest(donation, onValidationSuccess, onValidationFailed);
};

const processPledge = () => {
  getDonation();

  const onSuccess = (data) => {
    const success = (data) => {
      showConfirmation(data);
      ui.showPage();
    };

    const failed = (errors) => {
      console.log(errors);
      ui.showPage();
    };

    donationService.getDonationConfirmationHtml(data.Donation.Id, success, failed)
  };

  const onFailed = (errors) => {
    console.log(errors)
    ui.showPage();
  };

  ui.hidePage();
  donationService.createDonation(donation, onSuccess, onFailed);
};

export const processDonation = () => {
  if (utils.getPaymentMethod() === '0') {
    processCCPayment();
  } else {
    processPledge();
  }
};