import * as constants from "./constants";
import { hideGiftLevels, maybeGiftLevels, togglePreSelectedFundOnly } from "./funds";
import { resetAmount } from "./giftAmount";
import { toggleCancelButton } from "./nav";;
import { options } from "./options";
import { state } from "./state";
import { updateSummary } from "./summary";
import { inputMask } from "./ui";
import * as utils from "./utils";
import { addAmountValidator, removeValidator } from "./validation";

let goToFirstPanel = new CustomEvent('goToFirstPanel');
export let giftRowIndex = 0;


export const init = () => {
  initAnotherGift();
};


export const addGiftRow = () => {
  const gift = getGift();

  giftRowIndex++;

  document.querySelectorAll('.gifts').forEach((el, index) => {
    let clone = document.getElementById('giftRowTemplate').content.cloneNode(true);

    clone.firstElementChild.setAttribute('data-row-index', giftRowIndex);
    clone = createGift(clone, gift, index);
    addAmountValidator(clone.querySelector('.amount'));
    el.querySelector('.list-group').append(clone);
    // Dynamic validation for amount field
    addAmountValidator(`amount-${giftRowIndex}-${index}`);
    // Add input mask to amount field
    inputMask(document.querySelector(`[name="amount-${giftRowIndex}-${index}"]`));
  });
  initGiftActions();
  updateGiftAmountTotal();
  updateSummary();
}

const createGift = (el, gift, index) => {
  el.querySelector('.gift-name').innerText = gift.isSearchResult ? utils.getSelectedOptionText(gift.searchResult) : (gift.isSpecifiedFund ? document.getElementById('specify-fund').value : utils.getSelectedOptionText(gift.giftFund));
  el.querySelector('.amount').value = utils.numToFloat(gift.giftAmount, 2);
  el.querySelector('.amount').name = `amount-${giftRowIndex}-${index}`;
  el.querySelector('.gift-category').value = document.querySelector('.category.active').textContent;
  el.querySelector('.gift-category').name = `gift-${giftRowIndex}-${index}`;
  el.querySelector('.gift-unit').value = gift.isSpecifiedFund || gift.isSearchResult ? '' : (gift.giftUnit ? utils.getSelectedOptionValue(gift.giftUnit) : '');
  el.querySelector('.gift-unit').name = `gift-unit-${giftRowIndex}-${index}`;
  el.querySelector('.gift-area').value = gift.isSpecifiedFund || gift.isSearchResult ? '' : (gift.giftArea ? utils.getSelectedOptionValue(gift.giftArea) : '');
  el.querySelector('.gift-area').name = `gift-area-${giftRowIndex}`;
  el.querySelector('.gift-fund').value = gift.isSearchResult ? utils.getSelectedOptionValue(gift.searchResult) : (gift.isSpecifiedFund ? guids.defaultFund : (gift.giftFund ? utils.getSelectedOptionValue(gift.giftFund) : ''));
  el.querySelector('.gift-fund').name = `gift-fund-${giftRowIndex}-${index}`;
  el.querySelector('.fund-search').value = gift.isSearchResult && !gift.isSpecifiedFund ? true : false;
  el.querySelector('.fund-search').name = `fund-search-${giftRowIndex}-${index}`;
  el.querySelector('.fund-specified').value = gift.isSpecifiedFund ? true : false;
  el.querySelector('.fund-specified').name = `fund-specified-${giftRowIndex}-${index}`;
  return el;
};

const editGift = (e) => {
  state.isEditingGift = true;
  state.isAnotherGift = false;
  restoreGift(e.currentTarget);
  if (options.btnCancel) {
    toggleCancelButton();
  }
  window.dispatchEvent(goToFirstPanel);
};

const getCategory = (gift) => {
  return Array.from(document.querySelectorAll('.funds .tab-pane')).filter(el => {
    return el.dataset.category === gift.querySelector('.gift-category').value;
  });
};

const getCategoryBtn = (gift) => {
  return Array.from(document.querySelectorAll('.category')).filter(el => {
    return el.textContent === gift.querySelector('.gift-category').value;
  });
};

const getGift = () => {
  let gift = null;
  const category = document.querySelector('.funds .tab-pane.active');
  gift = {
    searchResult: document.getElementById('fund-search-results'),
    isSearchResult: utils.getSelectedOptionValue(document.getElementById('fund-search-results')) !== '',
    isSpecifiedFund: document.getElementById('is-specify-fund').checked && document.getElementById('specify-fund').value !== '',
    giftUnit: category.querySelector('.unit-select') ? category.querySelector('.unit-select') : null,
    giftArea: category.querySelector('.area-select') ? category.querySelector('.area-select') : null,
    giftFund: category.querySelector('.fund-select') ? category.querySelector('.fund-select') : null,
    giftAmount: getGiftAmount()
  };
  return gift;
};

const getGiftAmount = () => {
  const selectedAmount = document.querySelector('[name="gift-amount"]:checked');
  let giftAmount = null;

  if (selectedAmount) {
    giftAmount = selectedAmount.value;
  } else if (options.otherAmount) {
    giftAmount = constants.elements.otherAmount.value;
  }
  return Number(giftAmount);
};

const getGiftIndex = (gift) => {
  return gift.getAttribute('data-row-index');
};

const initAnotherGift = () => {
  document.querySelectorAll('.btn-add').forEach(el => {
    el.addEventListener('click', e => {
      state.isEditingGift = false;
      state.isAnotherGift = true;
      if (options.btnCancel) {
        toggleCancelButton();
      }
      window.dispatchEvent(goToFirstPanel);
    });
  });
};

const updateGiftAmounts = (value, index) => {
  document.querySelectorAll(`.gift[data-row-index="${index}"] .amount`).forEach(el => {
    el.value = value;
  });
};

export const initGiftActions = () => {
  document.querySelectorAll('.btn-edit').forEach(function(el) {
    el.removeEventListener('click', editGift);
    el.addEventListener('click', editGift);
  });

  document.querySelectorAll('.btn-remove').forEach(function(el) {
    el.removeEventListener('click', removeGift);
    el.addEventListener('click', removeGift);
  });

  document.querySelectorAll('.gift .amount').forEach(el => {
    el.addEventListener('input', e => {
      // if (e.isTrusted) {
      updateGiftAmounts(e.currentTarget.value, utils.getClosestEl(e.currentTarget, '.gift').dataset.rowIndex);
      updateGiftAmountTotal();
      // }
    });
  });
};

const removeGift = (e) => {
  const rowIndex = utils.getClosestEl(e.currentTarget, '.gift').getAttribute('data-row-index');
  let giftLists = null;
  let gifts = null;

  const removeRow = new Promise((resolve, reject) => {
    document.querySelectorAll('[data-row-index="' + rowIndex + '"]').forEach(function(el) {
      removeValidator(el.querySelector('.amount'));
      el.remove();
    });
    resolve();
  });

  removeRow.then(() => {
    gifts = document.querySelectorAll('.gift');
    if (gifts.length > 0) {
      giftLists = document.querySelectorAll('.gifts');
      giftRowIndex = gifts.length / giftLists.length;
      giftLists.forEach(function(el) {
        gifts = el.querySelectorAll('.gift');
        gifts.forEach(function(el, index) {
          el.setAttribute('data-row-index', (index + 1));
        });
      });
      updateGiftAmountTotal();
      updateSummary();
    } else {
      giftRowIndex = 0;
      resetCategories();
      resetFunds();
      resetSearch();
      hideGiftLevels();
      resetAmount();
      goToFirstPanel.resetNav = true;
      window.dispatchEvent(goToFirstPanel);
    }
  });
};

export const resetCategories = () => {
  document.querySelectorAll('.category, .gift-info .tab-pane').forEach(el => {
    el.classList.remove(constants.classes.activeClass);
  });

  // Activate first category tab
  document.querySelector('.category').click();
};

export const resetFunds = () => {
  // Reset select values
  document.querySelectorAll('.unit-select, .area-select, .fund-select').forEach(el => {
    el.value = '';
  });

  // Hide cascading selects
  document.querySelectorAll('.funds .tab-pane:not(#featured) .row:not(.nav):not(:first-child)').forEach(el => {
    el.classList.add(constants.classes.hiddenClass);
  });
};

export const resetSearch = () => {
  let el = null;
  // Reset search field
  if (options.search) {
    el = document.getElementById('fund-search');
    if (el) {
      el.value = '';
      utils.triggerEvent(el, 'keyup');
    }
  }

  // Reset specify fund
  if (options.specifyFund) {
    el = document.getElementById('is-specify-fund');
    if (el) {
      if (el.checked) {
        el.click();
      }
    }
    el = document.getElementById('specify-fund');
    if (el) {
      el.value = '';
    }
  }
};

const restoreAmount = (gift) => {
  const tryMatchingAmount = (amount) => {
    const matchedAmount = document.querySelector(`[name="gift-amount"][value="${amount}"]`);

    if (matchedAmount) {
      matchedAmount.click();
      return true;
    }
  };

  const setOtherAmount = (amount) => {
    document.getElementById('otherAmount').value = amount;
  };

  let amount = gift.querySelector('.amount').value;

  amount = String(Number.isInteger(Number(amount)) ? parseInt(amount) : amount);
  if (tryMatchingAmount(amount)) {
    return;
  } else {
    setOtherAmount(amount);
  }
};

const restoreCategory = (category) => {
  category.click();
};

const restoreDropdown = (dropdown, val) => {
  utils.selectOption(dropdown, val);
}

const restoreDropdowns = (gift, cat) => {
  const unitSelect = cat.querySelector('.unit-select');
  const areaSelect = cat.querySelector('.area-select');
  const fundSelect = cat.querySelector('.fund-select');

  if (unitSelect && gift.querySelector('.gift-unit').value !== '') {
    restoreDropdown(unitSelect, gift.querySelector('.gift-unit').value);
  }
  if (areaSelect && gift.querySelector('.gift-area').value !== '') {
    restoreDropdown(areaSelect, gift.querySelector('.gift-area').value);
    utils.getClosestEl(areaSelect, '.row').classList.remove(constants.classes.hiddenClass);
  }
  if (fundSelect && gift.querySelector('.gift-fund').value !== '') {
    restoreDropdown(fundSelect, gift.querySelector('.gift-fund').value);
    utils.getClosestEl(fundSelect, '.row').classList.remove(constants.classes.hiddenClass);
  }
};

const restoreDesignationSearch = (gift) => {
  let el = document.getElementById('fund-search');
console.log('document.getElementById("fund-search").value ' + document.getElementById('fund-search').value);
  el.value = gift.querySelector('.gift-name').innerText;
  utils.triggerEvent(el, 'keyup');
  setTimeout(function() {
    document.getElementById('fund-search-results').value = gift.querySelector('.gift-fund').value;
  }, 1000);
};

const restoreSpecifiedDesignation = (gift) => {
  utils.showElement(document.querySelector('.specify-fund'));
  document.getElementById('is-specify-fund').click();
  document.getElementById('specify-fund').value = gift.querySelector('.gift-name').innerText;
};

const restoreGift = (el) => {
  const gift = utils.getClosestEl(el, '.gift');
  const cat = getCategory(gift)[0];
  const catBtn = getCategoryBtn(gift)[0];

  state.giftIndex = getGiftIndex(gift);
console.log ('gift.querySelector(".fund-search").value ' + gift.querySelector('.fund-search').value)
 if (gift.querySelector('.fund-search').value === 'true') {
    restoreDesignationSearch(gift);
  } else if (gift.querySelector('.fund-specified').value === 'true') {
    restoreSpecifiedDesignation(gift);
  } else {
    restoreCategory(catBtn);
    restoreDropdowns(gift, cat);
  }
  maybeGiftLevels(gift.querySelector('.gift-fund').value);
  restoreAmount(gift);
};

export const updateGiftAmountTotal = () => {
  let giftAmountTotal = 0;

  document.getElementById('giftReview').querySelectorAll('.gift').forEach(function(el) {
    giftAmountTotal += Number(el.querySelector('.amount').value);
  });

  document.querySelectorAll('.js-total').forEach(function(el) {
    el.innerText = `$${Inputmask.format(String(utils.numToFloat(giftAmountTotal, 2)), { alias: 'currency' })}`;
  });
};

export const updateGiftRow = () => {
  const gift = getGift();
  const giftRows = document.querySelectorAll('[data-row-index="' + state.giftIndex + '"]');

  if (giftRows.length > 0) {
    giftRows.forEach(function(el, index) {
      el = createGift(el, gift, index);
    });
    updateGiftAmountTotal();
    updateSummary();
  }
};

export const updateGiftRowIndex = () => {
  return giftRowIndex++;
};