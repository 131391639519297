import { processDonation } from "./api";
import * as constants from "./constants";
import * as dayjs from 'dayjs';
import * as funds from "./funds";
import { resetAmount } from "./giftAmount";
import * as gifts from "./gifts";
import * as nav from "./nav";
import { options } from "./options";
import { saveForm } from './persistence';
import { state } from "./state";
import * as urlParams from "./urlParams";
import * as utils from "./utils";

// Use the algorithms
import luhn from 'formvalidation/algorithms/luhn';

// Use the validators
import between from 'formvalidation/validators/between';
import callback from 'formvalidation/validators/callback';
import greaterThan from 'formvalidation/validators/greaterThan';
import lessThan from 'formvalidation/validators/lessThan';
import notEmpty from 'formvalidation/validators/notEmpty';
import stringLength from 'formvalidation/validators/stringLength';

// Use the core library
import formValidation from 'formvalidation/core/Core';

// Use the plugins
import Aria from 'formvalidation/plugins/Aria';
import Bootstrap5 from 'formvalidation/plugins/Bootstrap5';
import Excluded from 'formvalidation/plugins/Excluded';
import Message from 'formvalidation/plugins/Message';
import Transformer from 'formvalidation/plugins/Transformer';
import Trigger from 'formvalidation/plugins/Trigger';
import Wizard from 'formvalidation/plugins/Wizard';

const plugins = urlParams.quickForm ? {
  aria: new Aria(),
  excluded: new Excluded(),
  bootstrap: new Bootstrap5(),
  message: new Message({
    container: '.row'
  }),
  trigger: new Trigger({
    delay: {
      // emailAddress: 1,
      // phoneNumber: 1,
      employeeId: 1
    },
    event: {
      emailAddress: 'blur',
      phoneNumber: 'blur',
      graduationYear: 'blur change input'
    }
  }),
} : {
  aria: new Aria(),
  excluded: new Excluded(),
  bootstrap: new Bootstrap5(),
  message: new Message({
    container: '.row'
  }),
  transformer: new Transformer({
    zip: {
      notEmpty: function(field, element, validator) {
        // Get the field value
        const value = element.value;
        // Remove the spaces from beginning and ending
        return value.trim();
      },
    },
  }),
  trigger: new Trigger({
    delay: {
      // emailAddress: 1,
      // phoneNumber: 1,
      employeeId: 1
    },
    event: {
      emailAddress: 'blur',
      phoneNumber: 'blur',
      graduationYear: 'blur change input'
    }
  }),
  wizard: new Wizard({
    stepSelector: constants.selectors.panelSelector,
    prevButton: constants.selectors.prevButton,
    nextButton: constants.selectors.nextButton,
  }),
};

const amountMinMessage = 'Online donations must be $5.00 or greater.';
const amountMaxMessage = 'For security reasons, online donations must be less than $50,000.00. Please call 800-768-9996 to make a credit card gift over the phone.';

const validators = {
  notEmpty: {
    message: 'Required'
  }
}

const amountValidators = {
  validators: {
    notEmpty: {
      message: 'Required'
    },
    greaterThan: {
      inclusive: true,
      message: amountMinMessage,
      min: 5
    },
    lessThan: {
      inclusive: true,
      message: amountMaxMessage,
      max: 50000
    }
  }
};

export const panelValid = new CustomEvent('panelValid');
export let fv = null;

export const init = () => {
  handlePanelValid();
  if (urlParams.quickForm) {
    initFvOneStep();
  } else {
    initFvMultiStep();
  }
  initFundInput();
}

export const addAmountValidator = (fieldName) => {
  fv.addField(fieldName, amountValidators);
};

export const disableValidator = (fieldName, validator = null) => {
  if (validator) {
    fv.disableValidator(fieldName, validator);
  } else {
    fv.disableValidator(fieldName);
  }
};

export const enableValidator = (fieldName, validator = null) => {
  if (validator) {
    fv.enableValidator(fieldName, validator);
  } else {
    fv.enableValidator(fieldName);
  }
  fv.revalidateField(fieldName);
};

const formatError = (e) => {
  const isOtherAmount = e.element === constants.elements.otherAmount;
  const row = e.element.closest('.row');
  const invalidFeedback = isOtherAmount ? constants.elements.otherAmount.nextElementSibling : (row ? row.querySelector('.invalid-feedback') : null);

  if ((e.element.type === 'radio' || isOtherAmount) && row && invalidFeedback) {
    row.append(invalidFeedback);
  }
};

const getRequiredFields = () => {
  const minGraduationYear = 1915;
  const maxGraduationYear = Number(dayjs().add(4, 'year').year());
  let requiredFields = {};

  // Build 'fields' object for formValidation
  document.querySelectorAll(':required:not([name="gift-amount"]):not(#otherAmount):not(#email):not(#phone):not(#zip):not(#employeeId):not(#employeeSignature):not(.optional):not(.verify)').forEach(el => {
    requiredFields[el.name] = {
      validators
    };
    // Also, trim text fields on input to avoid false positive when field value is " "
    if (el.type === 'email' || el.type === 'tel' || el.type === 'text') {
      el.addEventListener('blur', utils.trimValue);
      el.addEventListener('change', utils.trimValue);
    }
  });
  requiredFields['gift-amount'] = {
    validators: {
      callback: {
        message: 'Required',
        callback: function(input) {
          if (options.otherAmount) {
            fv.revalidateField('otherAmount');
            if (constants.elements.otherAmount.value !== '') {
              return true;
            } else {
              return getSelectedAmount();
            }
          } else {
            return getSelectedAmount();
          }
        }
      }
    }
  };
  requiredFields['maidenName'] = {
    validators: {
      regexp: {
        regexp: /^[a-z ,.'-]+$/i,
        message: 'Maiden name cannot contain numbers or special characters',
      }
    }
  };
  requiredFields['graduationYear'] = {
    validators: {
      between: {
        min: minGraduationYear,
        max: maxGraduationYear,
        message: `Please enter a year between 1915 and ${maxGraduationYear}`,
      }
    }
  };
  requiredFields['emailAddress'] = {
    validators: {
      notEmpty: {
        message: 'Required'
      },
      regexp: {
        regexp: /^[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/i,
        message: 'Please enter a valid email address'
      }
    }
  };
  requiredFields['phoneNumber'] = {
    validators: {
      notEmpty: {
        message: 'Required'
      },
      regexp: {
        regexp: /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/i,
        message: 'Please enter a valid US phone number'
      }
    }
  };
  requiredFields['zip'] = {
    validators: {
      notEmpty: {
        message: 'Required'
      },
      regexp: {
        regexp: /^(a-z|A-Z|0-9)*[^~`!.,@\?<>:;\'\"_#$%^&*()\//']*$/i,
        message: 'Please enter a valid zip/postal code'
      },
    }
  };
  if (options.otherAmount) {
    requiredFields['otherAmount'] = {
      validators: {
        callback: {
          message: 'Amount Required',
          callback: function(input) {
            if (input.value !== '') {
              return true;
            } else {
              return getSelectedAmount();
            }
          }
        },
        greaterThan: {
          inclusive: true,
          message: amountMinMessage,
          min: 5
        },
        lessThan: {
          inclusive: true,
          message: amountMaxMessage,
          max: 50000
        }
      }
    };
  }
  if (options.payroll) {
    requiredFields['employeeId'] = {
      validators: {
        notEmpty: {
          message: 'Required'
        },
        regexp: {
          regexp: /^\d{8}$/,
          message: 'Employee ID must be 8 characters',
        }
      }
    };
    requiredFields['employeeSignature'] = {
      validators: {
        notEmpty: {
          message: 'Required'
        },
        regexp: {
          regexp: /^[a-z ,.'-]+$/i,
          message: 'Full name cannot contain numbers or special characters',
        }
      }
    };
  }
  return requiredFields;
};

const getSelectedAmount = () => {
  return document.querySelectorAll('[name="gift-amount"]:checked').length > 0 ? true : false;
};

const handlePanelValid = () => {
  window.addEventListener('panelValid', e => {
    const panelIndex = e.index;
    const nextPanelBtn = document.querySelector(`[aria-controls="${constants.panels[panelIndex].id}"]`);
    const prevPanelBtn = document.querySelector(`[aria-controls="${constants.panels[panelIndex - 1].id}"]`);
    let prevPanelItem = null;

    // Go to next panel
    if (nextPanelBtn) {
      nextPanelBtn.click();
    }

    //Update nav pill text
    nav.updatePillText(panelIndex);

    //Update next button text
    nav.updateNextButtonText(panelIndex);

    // Might need to show back button
    nav.toggleBackButton(constants.panels[panelIndex].backBtn);

    // Indicate step complete
    if (prevPanelBtn) {
      prevPanelItem = prevPanelBtn.closest('.nav-item');
      if (prevPanelItem) {
        prevPanelItem.classList.add('complete');
      }
    }
  });
};

const initFundInput = () => {
  const handleFundInput = (e) => {
    if (e.currentTarget.value !== '') {
      toggleFundValidation('disable');
    } else {
      toggleFundValidation('enable');
    }
  };

  if (options.search) {
    constants.elements.searchFundResults.addEventListener('change', handleFundInput);
  }
  if (options.specifyFund) {
    constants.elements.specifyFund.addEventListener('input', handleFundInput);
  }
};

const initFvMultiStep = () => {
  const requiredFields = getRequiredFields();
  fv = formValidation(
      document.getElementById('adf'), {
        fields: requiredFields,
        plugins
      }).on('core.element.validated', function(e) {
      formatError(e);
    }).on('plugins.wizard.step.active', function(e) {
      //console.log(e);
    })
    .on('plugins.wizard.step.invalid', function(e) {
      scrollToError();
    })
    .on('plugins.wizard.step.valid', function(e) {
      // Add gift if on first panel
      if (e.step === 1) {
        if (state.isEditingGift) {
          gifts.updateGiftRow();
        } else if (state.preselectedFundOnly) {
          const designation = funds.getPreSelectedData();
          const amount = utils.getGiftAmount();

          if (designation && amount) {
            funds.addPreSelectedDonation(designation.lookupId, amount, designation.editStatus, designation.removeStatus);
            state.preselectedFundOnly = false;
            funds.togglePreSelectedFundOnly('hide');
          }
        } else {
          gifts.addGiftRow();
        }
        gifts.resetCategories();
        gifts.resetFunds();
        gifts.resetSearch();
        funds.hideGiftLevels();
        resetAmount();
      }
      // Go to next panel
      if (constants.panels[e.step]) {
        panelValid.index = e.step;
        window.dispatchEvent(panelValid);
        utils.goToTop();
      }
      // Save form state
      saveForm(e.step);
    })
    .on('plugins.wizard.valid', function(e) {
      processDonation();
    });
};

const initFvOneStep = () => {
  const requiredFields = getRequiredFields();
  fv = formValidation(
      document.getElementById('adf'), {
        fields: requiredFields,
        plugins
      }).on('core.element.validated', function(e) {
      formatError(e);
    }).on('core.form.invalid', function(e) {
      scrollToError();
    })
    .on('core.form.valid', function(e) {
      processDonation();
    });

  constants.elements.btnNext.addEventListener('click', function() {
    fv.validate();
  });
};

export const removeValidator = (field) => {
  const fieldName = typeof field === 'string' ? field : field.name;

  fv.removeField(fieldName);
};

const scrollToError = () => {
  let firstInvalid = document.querySelector('.fv-plugins-bootstrap5-row-invalid');

  if (firstInvalid) {
    firstInvalid.scrollIntoView({ behavior: 'smooth' });
  }
};

const toggleFundValidation = (mode) => {
  document.querySelectorAll('.unit-select, .area-select, .fund-select').forEach(el => {
    if (mode === 'disable') {
      fv.disableValidator(el.name);
    } else if (mode === 'enable') {
      fv.enableValidator(el.name);
    }
  });
};