import * as utils from "./utils";
import Fuse from 'fuse.js'
import { allDesignations, maybeGiftLevels } from "./funds";

export const init = () => {
  search();
};

const searchFunds = document.querySelector('.fund-search-select');
const specifyFund = document.querySelector('.specify-fund');

const getSearchResults = (fuse) => {
  const searchBox = document.getElementById('fund-search');
  const searchField = document.getElementById('fund-search-field');
  const searchResults = document.getElementById('fund-search-results');
  const searchResultsLabels = document.querySelectorAll('.js-fund-search-result-count');
  const noResultsFound = document.getElementById('no-results-found');

  const useOrOperator = (str) => {
    return str.replace(/\s/g, ' | ');
  };

  let len = searchResults.length;
  for (let i = 1; i < len; i++) {
    searchResults.remove(1);
  }

  utils.hideElement(searchField);

  if (searchBox.value.length > 2) {
    const searchTerms = (useOrOperator(searchBox.value)).trim();
    let results = fuse.search(searchTerms);
    let resultsLen = results.length;
    let filteredResults = null;

    if (resultsLen > 0) {
      utils.hideElement(noResultsFound);
      utils.showElement(searchField);
      filteredResults = results.reduce((unique, result) => {
        if (!unique.some(obj => obj.item.guid === result.item.guid)) {
          unique.push(result);
        }
        return unique;
      }, []);
      resultsLen = filteredResults.length;
      utils.addOptions(searchResults, filteredResults, resultsLen);
      searchResultsLabels.forEach(el => {
        el.innerText = 'We found ' + resultsLen + ' matching funds';
      });
    } else {
      utils.hideElement(searchField);
      utils.showElement(noResultsFound);
    }
  } else {
    utils.hideElement(searchField);
    utils.hideElement(noResultsFound);
  }
};

const search = () => {
  const searchBox = document.getElementById('fund-search');
  const searchResults = document.getElementById('fund-search-results');
  const options = {
    shouldSort: true,
    threshold: 0.3,
    minMatchCharLength: 3,
    keys: ['name'],
    distance: 115,
    useExtendedSearch: true
  };
  let fuse = new Fuse(allDesignations, options);

  searchBox.addEventListener('focus', e => {
    utils.showElement(specifyFund);
  });

  searchBox.addEventListener('keyup', utils.debounce(e => {
    getSearchResults(fuse);
  }, 250));

  searchBox.addEventListener('change', e => {
    getSearchResults(fuse);
  });

  searchResults.addEventListener('change', e => {
    const redirectUrl = utils.getSelectedOptionRedirect(e.currentTarget)
    const selectedFund = utils.getSelectedOptionValue(e.currentTarget);

    if (redirectUrl) {
      utils.redirect(redirectUrl);
    } else {
      maybeGiftLevels(selectedFund);
    }
  })
};